@import "../../sassStyles/global";
@import "../../sassStyles/variables";

.banner-bg {
  background: url("../../../public/backgrounds/banner-bg.png") no-repeat;
  background-size: cover;
  min-height: 900px;
}

// *** HOME BANNER SECTION ***
.home-banner {
  padding-top: 17rem;
  padding-bottom: 15rem;
  position: relative;

  .banner-heading {
    width: 100%;
    margin: auto;
    margin-bottom: 2.688rem;
    font-weight: 300;
  }
}

.partners-mobile-img,
.partners-small-laptop,
.partners-small-tab {
  display: none;
}

// *** HOME BANNER SECTION END ***

// *** SOLUTIONS SEGMENTATION SECTION ***
.solutions-segmentation {
  margin: auto;
  z-index: 4;
  border-radius: 0.625rem 0.625rem 0 0;
  width: 80rem;
  margin-top: -17.5rem;
  text-align: left;
  height: 20rem;
  vertical-align: middle;
  position: absolute;
  left: 0;
  right: 0;

  .segmentation-heading {
    margin-bottom: 0;
    font-weight: 700;
  }


  .segmentation-info {
    font-weight: 400;
    // font-size: 18px;
    line-height: 131.5%;
    min-height: 70px;
    margin: auto;
    margin-top: 11px;
    margin-bottom: 30px;
    color: $fontsecondaryColor;
    width: 95%;
    margin-left: 0;
  }

  .solutions-flex-container {
    width: 90%;
    margin: auto;
    gap: 45px;
  }

  .segmentation-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .segmentation-bg {
    background: url("../../../public/backgrounds/segmentation-bg.png") no-repeat;
    background-size: cover;
    width: 550px;
    height: auto;
    background-position: center;
    box-shadow: 8.23px 8.23px 49.4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 21.482px;
    padding-left: 40px;
    padding-top: 30px;

    .btn {
      margin-bottom: 30px;
    }

  }

  .icon-size {
    width: 105px;
    height: 105px;
    margin-bottom: 10px;
  }

  .flex-container {
    justify-content: space-around;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .flex-item {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }

  .fill {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}

// *** SOLUTIONS SEGMENTATION SECTION END ***

// *** PARTNERS SECTION ***
.partners-section {
  align-items: center;
  position: relative;
  padding-top: 8rem;
  z-index: 3;

  .partners-padding {
    padding-top: 180px;
  }

  .glc-products-heading {
    width: 75%;
  }

  .partners-info {
    width: 59%;
    line-height: 150%;
    margin-top: 48px;
    margin-bottom: 63px;
    z-index: 2;
    position: relative;
  }

  .text-element {
    width: 85%;
    line-height: 120%;
    margin-left: auto;
    margin-right: auto;
  }

  .product-text {
    width: 81%;
    margin: auto;
  }

  .partners-background {
    background: url("../../../public/backgrounds/partner-logos.svg") no-repeat;
    background-size: cover;
    min-height: 950px;
  }

}

// *** PARTNERS SECTION END ***

// *** EXPERTISE SECTION START ***
.expertise-section {
  padding-top: 100px;
  padding-bottom: 150px;

  .expertise-subheading {
    width: 80%;
    margin-top: 115px;
    margin-bottom: 50px;
  }

  .expertise-heading,
  .expertise-heading span {
    line-height: 76px;
    margin-bottom: 90px;
  }

  .expertise-grid {
    display: flex;
    flex-direction: row;
    gap: 34px;

    .column {
      flex: 1 1 48%;
    }

    .column-one {
      background-color: $bgPurpleColor;
      border-radius: 12px;

      .image-container {
        justify-content: center;
        display: flex;
        text-align: center;
      }

      img {
        width: 95%;
        margin: auto;
        margin-top: 90px;
      }

      .expertise-content {
        padding: 0 30px 60px 60px;
      }
    }

    .grid-two-column-one {
      background-color: $primaryRedColor;
      border-radius: 12px;
      padding: 120px 25px 120px 50px;
      margin-bottom: 20px;

      h3 {
        margin-bottom: 100px;
      }

    }

    .grid-two-column-two {
      background-color: $btnSecondaryBg;
      border-radius: 12px;
      padding: 120px 15px 120px 50px;

      h3 {
        margin-bottom: 100px;
      }

    }
  }
}

// *** EXPERTISE SECTION END ***

// *** SOLUTIONS SECTION START ***
.our-solutions-container {
  padding-top: 120px;


  .solutions-heading {
    margin-bottom: 92px;
  }

  .solutions-element {
    padding: 50px 65px 50px 65px;
    border-radius: 12px;
    position: relative;
    background-color: $solutionsBgColor;
    flex: 1;

    .text-element {
      font-weight: 600;
      margin-bottom: 25px;
    }

    .solutions-link {
      color: $seeMoreLinkColor !important;
      font-size: 18px;
      text-decoration: none;
      font-weight: 600;
    }
  }

  .home-solutions-bg {
    background: url("../../../public/backgrounds/home-solutions-bg.svg") no-repeat;
    background-size: contain;
  }

  .solutions-flex-container {
    gap: 32px;
    max-width: 90%;
    margin: auto;
  }

  .hr-solutions {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .badge {
    position: absolute;
    top: -14px;
    font-size: 28px;
    font-weight: 600;
    padding-top: 7px;
    padding-bottom: 7px;
    text-transform: none;
  }
}

// *** SOLUTIONS SECTION END ***

// *** PRODUCTS SECTION START ***
.our-products-container {
  position: relative;
  padding-top: 100px;
  padding-bottom: 150px;

  .products-heading {
    margin-bottom: 150px;
    text-align: center;
  }

  .image-margin {
    margin-top: -3rem;
    margin-bottom: 2rem;
  }

  .product-element {
    height: auto;
    border-radius: 12px;

    .btn {
      margin-bottom: 40px;
    }
  }

  .product-text {
    width: 79%;
    margin: auto;
    min-height: 100px;
    margin-bottom: 15px;


  }
}

// *** PRODUCTS SECTION END ***

// *** GLC RESOURCES SECTION START ***
.resource-container {
  padding-bottom: 180px;

  .fm-resource-heading {
    margin-bottom: 100px;
  }

  .slider-container {
    .slick-slide {
      width: 382px !important;
      margin-right: 16px;
      margin-left: 16px;
    }

  }

  .content {
    padding: 20px;
    text-align: left !important;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    min-height: 240px;
    padding-bottom: 20px;

    h4 {
      height: 50px;
    }

    p {
      margin-top: 20px;
    }
  }

  .slide-title {
    text-align: left;
  }

  .slick-dots {
    bottom: -73px;
  }

  .slick-dots li button:before {
    font-family: 'slick';
    font-size: 16px;
  }

}

// *** GLC RESOURCES SECTION END ***

// *** GLC DEMO REQUEST SECTION ***
.glc-request-demo {
  padding-bottom: 11.5rem;
  position: relative;

  .request-demo-wrapper {
    border-radius: 12px;
    padding: 70px;

    h6 {
      width: 90%;
    }

    .glc-request-demo-subheading {
      font-weight: 400;
      font-style: normal;
      margin-bottom: 1.313rem;
    }

    .btn-flex-container {
      gap: 20px;
      margin-top: 28px;
    }
  }
}

@media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
  .banner-bg {
    background: url("../../../public/backgrounds/banner-bg.png") no-repeat;
    background-size: cover;
    min-height: 730px;
  }

  .home-banner {
    padding-top: 14rem;
    padding-bottom: 13rem;
    position: relative;

    .banner-heading {
      margin: auto;
      margin-bottom: 2.688rem;
      font-weight: 300;
      width: 95%;
    }
  }

  // *** SOLUTIONS SEGMENTATION SECTION ***
  .solutions-segmentation {
    margin-top: -14rem;
    width: 66rem;

    .segmentation-bg {
      border-radius: 11px;
    }

    .segmentation-info {
      line-height: 21px;
      font-size: 16px;
      min-height: 63px;
      margin: auto;
      margin-top: 10px;
      margin-bottom: 17px;
      width: 92%;
      margin-left: 0;
    }

    .segmentation-bg {
      width: 500px;
      height: auto;
    }

    .icon-size {
      width: 75px;
      height: 75px;
      margin-bottom: 10px;
    }
  }

  // *** SOLUTIONS SEGMENTATION SECTION END ***

  // *** PARTNERS SECTION START ***
  .partners-section {
    align-items: center;
    position: relative;
    padding-top: 8rem;
    z-index: 3;

    .partners-padding {
      padding-top: 70px;
    }

    .glc-products-heading {
      width: 75%;
    }

    .partners-info {
      width: 60%;
      line-height: 150%;
      margin-top: 20px;
      margin-bottom: 63px;
      z-index: 2;
      position: relative;
    }

    .text-element {
      width: 85%;
      line-height: 120%;
      margin-left: auto;
      margin-right: auto;
    }

    .product-text {
      width: 81%;
      margin: auto;
    }

    .partners-background {
      background: url("../../../public/backgrounds/partner-logos-1440.svg") no-repeat;
      background-size: cover;
      min-height: 675px;
    }
  }

  // *** PARTNERS SECTION START ***

  // *** EXPERTISE SECTION START ***
  .expertise-section {
    padding-top: 70px;
    padding-bottom: 70px;

    .expertise-subheading {
      width: 80%;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .expertise-heading,
    .expertise-heading span {
      line-height: 94px;
      margin-bottom: 40px;
    }

    .expertise-grid {
      .column-one {
        img {
          width: 90%;
          margin: auto;
          margin-top: 45px;
          height: auto;
        }

        .expertise-content {
          padding: 0 30px 60px 60px;
        }
      }

      .grid-two-column-one {
        background-color: $primaryRedColor;
        border-radius: 11px;
        padding: 60px 35px 60px 48px;
        margin-bottom: 20px;

        h3 {
          margin-bottom: 60px;
        }

      }

      .grid-two-column-two {
        background-color: $btnSecondaryBg;
        border-radius: 11px;
        padding: 60px 35px 60px 48px;

        h3 {
          margin-bottom: 60px;
        }

      }
    }
  }

  // *** EXPERTISE SECTION END ***

  // *** SOLUTIONS SECTION START ***
  .our-solutions-container {
    padding-top: 100px;

    .solutions-element {
      padding: 40px 50px 33px 50px;

      .solution-text {
        font-size: 16px;
      }

      .text-element {
        font-weight: 600;
        margin-bottom: 5px;
      }

      .solutions-link {
        font-size: 16px;
      }
    }

    .badge {
      font-size: 25px;
    }
  }

  // *** SOLUTIONS SECTION END ***

  // *** PRODUCTS SECTION START ***
  .our-products-container {
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;

    img {
      height: 160px;
      width: auto;
    }


    .products-heading {
      margin-bottom: 100px;
      text-align: center;
    }


    .product-element {
      height: auto;

    .btn {
      margin-bottom: 40px;
    }
    }

    .product-text {
      width: 79%;
      margin: auto;
      margin-bottom: 15px;
      height: 96px;
      font-size: 16px;
    }

    .btn-read-more {
      margin-top: 28px !important;

    }
  }

  // *** PRODUCTS SECTION END ***

  // *** GLC RESOURCES SECTION START ***
  .resource-container {
    padding-bottom: 150px;

    .fm-resource-heading {
      margin-bottom: 100px;
    }

    .slider-container {
      .slick-slide {
        width: 330px !important;
        margin-right: 16px;
        margin-left: 16px;

        img {
          width: 330px !important;
        }
      }
    }

    .content {
      padding: 20px;
      text-align: left !important;
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
      height: 213px;

      h4 {
        height: 50px;
      }

      p {
        margin-top: 20px;
      }

    }

    .slick-dots {
      bottom: -73px;
    }

    .slick-dots li button:before {
      font-family: 'slick';
      font-size: 14px;
    }
  }

  // *** GLC RESOURCES SECTION END ***

  // *** FM DEMO REQUEST SECTION ***
  .glc-request-demo {
    padding-bottom: 115px;
    position: relative;

    img {
      height: 275px;
    }

    p {
      font-size: 20px;
    }

    .request-demo-wrapper {
      border-radius: 12px;
      padding: 70px;
    }
  }
}

@media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
  .banner-bg {
    background: url("../../../public/backgrounds/banner-bg.png") no-repeat;
    background-size: cover;
    min-height: 562px;
  }

  .home-banner {
    padding-top: 10rem;
    padding-bottom: 12rem;
    position: relative;

    .banner-heading {
      margin: auto;
      margin-bottom: 2.688rem;
      font-weight: 300;
      width: 90%;
    }
  }

  // *** SOLUTIONS SEGMENTATION SECTION ***
  .solutions-segmentation {
    margin-top: -11rem;
    width: 56.25rem;

    .segmentation-bg {
      padding-top: 10px;
      padding-left: 28px;
      border-radius: 8.5px;
    }

    .segmentation-heading {
      // font-size: 26px;
      margin-top: 12px;
    }

    .segmentation-info {
      font-size: 14px;
      line-height: 18px;
      min-height: 60px;
      margin: auto;
      margin-top: 7px;
      margin-bottom: 7px;
      width: 92%;
      margin-left: 0;
    }

    .segmentation-bg {
      width: 420px;
      height: 230px;
    }

    .icon-size {
      width: 58px;
      height: 58px;
    }
  }

  // *** SOLUTIONS SEGMENTATION SECTION END ***

  // *** PARTNERS SECTION START ***
  .partners-section {
    align-items: center;
    position: relative;
    padding-top: 97px;
    z-index: 3;

    .partners-padding {
      padding-top: 70px;
    }

    .glc-products-heading {
      width: 60%;
    }

    .partners-info {
      width: 60%;
      line-height: 150%;
      margin-top: 30px;
      margin-bottom: 63px;
      z-index: 2;
      position: relative;
      font-size: 14px;
    }

    .text-element {
      width: 85%;
      line-height: 120%;
      margin-left: auto;
      margin-right: auto;
    }

    .product-text {
      width: 81%;
      margin: auto;
    }

    .partners-background {
      background: url("../../../public/backgrounds/partner-logos-1200.svg") no-repeat;
      background-size: cover;
      min-height: 560px;
    }
  }

  // *** PARTNERS SECTION START ***

  // *** EXPERTISE SECTION START ***
  .expertise-section {
    padding-top: 55px;
    padding-bottom: 55px;

    .expertise-subheading {
      width: 80%;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    .expertise-heading,
    .expertise-heading span {
      line-height: 94px;
      margin-bottom: 40px;
    }

    .expertise-grid {
      .column-one {
        img {
          width: 413px;
          margin: auto;
          margin-top: 50px;
          height: auto;
        }

        .expertise-content {
          padding: 0 30px 30px 30px;
        }
      }

      .grid-two-column-one {
        background-color: $primaryRedColor;
        border-radius: 11px;
        padding: 45px 25px 40px 25px;
        margin-bottom: 20px;

        h3 {
          margin-bottom: 45px;
        }

      }

      .grid-two-column-two {
        background-color: $btnSecondaryBg;
        border-radius: 11px;
        padding: 45px 25px 40px 25px;

        h3 {
          margin-bottom: 45px;
        }

      }
    }
  }

  // *** EXPERTISE SECTION END ***

  // *** SOLUTIONS SECTION START ***
  .our-solutions-container {
    padding-top: 70px;

    .solutions-element {
      padding: 40px 42px 25px 42px;

      .text-element {
        font-weight: 600;
        margin-bottom: 10px;
      }

      .solution-text {
        font-size: 13px;
      }

      .badge {
        position: absolute;
        top: -14px;
        font-size: 18px;
        font-weight: 600;
        padding-top: 7px;
        padding-bottom: 7px;
        text-transform: none;
      }

      .hr-solutions {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .solutions-link {
        font-size: 13px;
      }
    }
  }

  // *** SOLUTIONS SECTION END ***

  // *** PRODUCTS SECTION START ***
  .our-products-container {
    position: relative;
    padding-top: 90px;
    padding-bottom: 90px;

    img {
      height: 120px;
      width: auto;
    }


    .products-heading {
      margin-bottom: 100px;
      text-align: center;
    }


    .product-element {
      height: 345px;
      border-radius: 8.5px;
    }

    .product-text {
      width: 83%;
      margin: auto;
      margin-bottom: 10px;
      height: 65px;
      font-size: 14px;
      line-height: 19px;
    }

    .image-margin {
      margin-bottom: 1.5rem;
    }

    .btn-read-more {
      margin-top: 40px !important;
    }
  }

  // *** PRODUCTS SECTION END ***

  // *** GLC RESOURCES SECTION START ***
  .resource-container {
    padding-bottom: 150px;

    .fm-resource-heading {
      margin-bottom: 100px;
    }

    .slider-container {
      .slick-slide {
        width: 330px !important;
        margin-right: 16px;
        margin-left: 16px;

        img {
          width: 330px !important;
        }
      }
    }

    .content {
      padding: 20px;
      padding-top: 0 !important;
      text-align: left !important;
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
      height: 195px;

      .slide-title {
        height: 50px;
        font-size: 19px;
        line-height: 28px;
      }

      p {
        margin-top: 15px;
      }

    }

    .slick-dots {
      bottom: -73px;
    }

    .slick-dots li button:before {
      font-family: 'slick';
      font-size: 14px;
    }

  }

  // *** GLC RESOURCES SECTION END ***
  // *** GLC DEMO REQUEST SECTION ***
  .glc-request-demo {
    padding-bottom: 115px;
    position: relative;

    img {
      height: 220px;
    }

    p {
      font-size: 14px;
      width: 85%;
      margin-bottom: 2rem;
    }

    .request-demo-wrapper {
      border-radius: 8.5px;
      padding: 45px;
    }
  }
}

@media (min-width: $midSmallDevice) and (max-width: $maxSmallDevice) {
  .banner-bg {
    background: url("../../../public/backgrounds/banner-bg.png") no-repeat;
    background-size: cover;
    min-height: 560px;
  }

  .home-banner {
    padding-top: 9rem;
    padding-bottom: 10rem;
    position: relative;

    .banner-heading {
      margin: auto;
      margin-bottom: 2.688rem;
      font-weight: 300;
      width: 75%;
      line-height: 120%;
    }
  }

  // *** SOLUTIONS SEGMENTATION SECTION ***
  .solutions-segmentation {
    margin-top: -11rem;
    width: 53rem;

    .segmentation-bg {
      padding-top: 10px;
      padding-left: 28px;
      border-radius: 8px;
    }

    .segmentation-heading {
      margin-top: 12px;
    }

    .segmentation-info {
      font-size: 12px;
      line-height: 16px;
      min-height: 50px;
      margin: auto;
      margin-top: 13px;
      margin-bottom: 13px;
      width: 92%;
      margin-left: 0;
    }

    .segmentation-bg {
      width: 375px;
      height: 240px;
    }

    .icon-size {
      width: 55px;
      height: 55px;
    }
  }

  // *** SOLUTIONS SEGMENTATION SECTION END ***

  // *** PARTNERS SECTION START ***
  .partners-section {
    align-items: center;
    position: relative;
    padding-top: 80px;
    z-index: 3;

    .partners-padding {
      padding-top: 70px;
    }

    .glc-products-heading {
      width: 80%;
    }

    .partners-info {
      width: 100%;
      line-height: 150%;
      margin-top: 30px;
      margin-bottom: 40px;
      z-index: 2;
      position: relative;
      font-size: 14px;
    }

    .text-element {
      width: 85%;
      line-height: 120%;
      margin-left: auto;
      margin-right: auto;
    }

    .product-text {
      width: 81%;
      margin: auto;
    }

    .partners-background {
      background: none;
      min-height: 690px;
    }

    .partners-small-laptop {
      display: flex;


      img {
        margin: auto;
        margin-bottom: 0;
      }
    }
  }

  // *** PARTNERS SECTION END ***

  // *** EXPERTISE SECTION START ***
  .expertise-section {
    padding-top: 60px;
    padding-bottom: 60px;

    .expertise-subheading {
      width: 80%;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    .expertise-heading,
    .expertise-heading span {
      line-height: 110%;
      margin-bottom: 40px;
    }

    .expertise-grid {
      .column-one {
        img {
          width: 390px;
          margin: auto;
          margin-top: 50px;
          height: auto;
        }

        .expertise-content {
          padding: 0 30px 30px 30px;
        }
      }

      .grid-two-column-one {
        background-color: $primaryRedColor;
        border-radius: 8px;
        padding: 45px 25px 40px 25px;
        margin-bottom: 20px;

        h3 {
          margin-bottom: 45px;
        }

      }

      .grid-two-column-two {
        background-color: $btnSecondaryBg;
        border-radius: 8px;
        padding: 45px 25px 40px 25px;

        h3 {
          margin-bottom: 45px;
        }

      }
    }
  }

  // *** EXPERTISE SECTION END ***

  // *** SOLUTIONS SECTION START ***
  .our-solutions-container {
    padding-top: 60px;

    .solutions-heading {
      margin-bottom: 50px;
    }

    .solutions-element {
      padding: 30px 35px 25px 35px;

      .text-element {
        font-weight: 600;
        margin-bottom: 10px;
      }

      .solution-text {
        font-size: 13px;
      }

      .badge {
        position: absolute;
        top: -14px;
        font-size: 18px;
        font-weight: 600;
        padding-top: 6px;
        padding-bottom: 6px;
        text-transform: none;
      }

      .hr-solutions {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .solutions-link {
        font-size: 13px;
      }
    }
  }

  // *** SOLUTIONS SECTION END ***

  // *** PRODUCTS SECTION START ***
  .our-products-container {
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;

    img {
      height: 115px;
      width: auto;
    }


    .products-heading {
      margin-bottom: 100px;
      text-align: center;
    }


    .product-element {
      height: 320px;
      border-radius: 8px;
    }

    .product-text {
      width: 83%;
      margin: auto;
      margin-bottom: 10px;
      height: 55px;
      font-size: 12px;
      line-height: 19px;
    }

    .image-margin {
      margin-bottom: 1.0rem;
    }

    .btn-read-more {
      margin-top: 40px !important;
    }

    .badge {
      font-size: 10px;
    }
  }

  // *** PRODUCTS SECTION END ***

  // *** GLC RESOURCES SECTION START ***
  .resource-container {
    padding-bottom: 120px;

    .fm-resource-heading {
      margin-bottom: 60px;
    }

    .slick-track {
      display: flex;
    }

    .slider-container {
      .slick-slide {
        width: 280px !important;
        margin-right: 16px;
        margin-left: 16px;

        img {
          width: 280px !important;
        }
      }
    }

    .content {
      padding: 15px;
      padding-top: 0 !important;
      text-align: left !important;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      height: 170px;

      .slide-title {
        height: 45px;
        font-size: 15px;
        line-height: 25px;
      }

      p {
        margin-top: 12px;
      }

    }

    .slick-dots {
      bottom: -73px;
    }

    .slick-dots li button:before {
      font-family: 'slick';
      font-size: 12px;
    }

  }

  // *** GLC RESOURCES SECTION END ***
  // *** GLC DEMO REQUEST SECTION ***
  .glc-request-demo {
    padding-bottom: 100px;
    position: relative;

    img {
      height: 220px;
    }

    p {
      font-size: 12px;
      width: 90%;
      margin-bottom: 2rem;
    }

    .request-demo-wrapper {
      border-radius: 8px;
      padding: 45px;
    }
  }
}
@media (max-width: $midSmallDevice) and (min-width: $maxTabDevice) {
  .glc-request-demo {
    padding-bottom: 50px;
    position: relative;

    .request-demo-wrapper {
      .demo-section-content{
        margin: auto;
      }
      border-radius: 8px;
      padding: 50px 20px 50px 20px;
      text-align: center;
      h3{
        width: 100%;
      }
      h6 {
        width: 90%;
      }
    }

    img {
      width: 100%;
      height: auto;
      margin-bottom: 50px;
    }




    p {
      font-size: 14px;
      margin-bottom: 30px;
    }

    .btn-flex-container {
      flex-direction: column;
      justify-content: center;
      margin: auto;
      margin-top: 28px;
    }

    .social-links {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }

}
@media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
  .banner-bg {
    background: url("../../../public/backgrounds/banner-mobile.png") no-repeat;
    background-size: cover;
    min-height: 930px;
  }

  .home-banner {
    padding-top: 9rem;
    padding-bottom: 9rem;

    .banner-heading {
      line-height: 40px;
      width: 80%;
    }
  }

  // *** SOLUTIONS SEGMENTATION SECTION ***
  .solutions-segmentation {
    .segmentation-container {
      flex-direction: column;
      gap: 50px;
    }

    width: auto;
    margin-top: -32rem;
    text-align: center;
    height: 20rem;
    vertical-align: middle;
    position: absolute;
    left: 0;
    right: 0;

    .segmentation-heading {
      margin-bottom: 0;
      font-weight: 700;
    }


    .segmentation-info {
      font-weight: 400;
      line-height: 131.5%;
      min-height: 47px;
      margin: auto;
      margin-top: 13px;
      margin-bottom: 13px;
      color: $fontsecondaryColor;
      width: 90%;
    }

    .segmentation-bg {
      background: url("../../../public/backgrounds/segmentation-bg.png") no-repeat;
      background-size: cover;
      width: 85%;
      margin: auto;
      height: auto;
      background-position: center;
      box-shadow: 8.23px 8.23px 49.4px 0 rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      padding: 10px;

      .btn {
        margin-bottom: 15px;
      }
    }

    .icon-size {
      width: 43px;
      height: 43px;
    }
  }

  // *** SOLUTIONS SEGMENTATION SECTION END ***
  // *** PARTNERS SECTION START ***
  .partners-section {
    align-items: center;
    position: relative;
    padding-top: 0;
    z-index: 3;

    .partners-padding {
      padding-top: 50px;
    }

    .glc-products-heading {
      width: 100%;
      line-height: 120%;
    }

    .partners-info {
      width: 90%;
      line-height: 150%;
      margin-top: 20px;
      margin-bottom: 35px;
      z-index: 2;
      position: relative;
    }

    .text-element {
      width: 85%;
      line-height: 120%;
      margin-left: auto;
      margin-right: auto;
    }

    .product-text {
      width: 81%;
      margin: auto;
    }

    .partners-background {
      background: none;
      min-height: 300px;
    }

    .partners-mobile-img {
      display: flex;

      img {
        margin: auto;
        margin-bottom: 50px;
      }
    }

    // *** PARTNERS SECTION START ***
  }

  // *** EXPERTISE SECTION START ***
  .expertise-section {
    padding-top: 50px;
    padding-bottom: 50px;

    .expertise-subheading {
      width: 90%;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    .expertise-heading,
    .expertise-heading span {
      line-height: 20px;
      margin-bottom: 40px;
    }

    .expertise-grid {
      flex-direction: column;

      .column-one {
        img {
          width: 90%;
          margin: auto;
          margin-top: 50px;
          height: auto;
        }

        .expertise-content {
          padding: 0 30px 50px 30px;
        }
      }

      .grid-two-column-one {
        background-color: $primaryRedColor;
        border-radius: 11px;
        padding: 50px 30px 50px 30px;
        margin-bottom: 20px;

        h3 {
          margin-bottom: 25px;
        }

      }

      .grid-two-column-two {
        background-color: $btnSecondaryBg;
        border-radius: 11px;
        padding: 50px 30px 50px 30px;

        h3 {
          margin-bottom: 25px;
        }

      }
    }
  }

  // *** SOLUTIONS SECTION START ***
  .our-solutions-container {
    padding-top: 50px;

    .solutions-heading {
      line-height: 38px;
      width: 70%;
      margin: auto;
      margin-bottom: 40px;
    }

    .solutions-flex-container {
      flex-direction: column;
      gap: 45px;
      max-width: 100%;
    }

    .home-solutions-bg {
      background: none;
    }

    .solutions-element {
      padding: 40px 30px 25px 30px;

      .text-element {
        font-weight: 600;
        margin-bottom: 10px;
      }

      .badge {
        position: absolute;
        top: -14px;
        font-size: 14px;
        font-weight: 600;
        padding-top: 7px;
        padding-bottom: 7px;
        text-transform: none;
      }

      .hr-solutions {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .solutions-link {
        font-size: 13px;
      }
    }
  }

  // *** PRODUCTS SECTION START ***
  .our-products-container {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      height: auto;
      width: 80%;
    }


    .products-heading {
      margin-bottom: 100px;
      text-align: center;
    }

    .products-wrapper {
      display: flex;
      gap: 90px;
    }

    .product-element {
      height: auto;
      border-radius: 8px;
    }

    .product-text {
      width: 85%;
      margin: auto;
      margin-bottom: 15px;
      height: auto;
      min-height: auto;
    }

    .btn-read-more {
      margin-top: 0;
      margin-bottom: 25px;

    }
  }

  // *** PRODUCTS SECTION END ***

  // *** GLC RESOURCES SECTION START ***
  .resource-container {
    padding-bottom: 100px;

    .fm-resource-heading {
      margin-bottom: 40px;
    }

    .slick-track {
      display: flex;
    }

    .slider-container {
      .slick-slide {
        width: 100% !important;
        margin: auto;
        margin-left: 0.5%;

        img {
          width: 100%;
        }

      }
    }

    .content {
      padding: 0 20px 10px 20px;
      text-align: left !important;
      border-bottom-right-radius: 8%;
      border-bottom-left-radius: 8px;
      height: auto;
      min-height: auto;

      h4 {
        height: 50px;
      }

      p {
        margin-top: 20px;
        font-size: 12px !important;
      }
    }

    .slick-dots {
      bottom: -55px;
    }

    .slick-dots li button:before {
      font-family: 'slick';
      font-size: 12px;
    }
  }

  // *** GLC RESOURCES SECTION END ***

  // *** FM DEMO REQUEST SECTION ***
  .glc-request-demo {
    padding-bottom: 50px;
    position: relative;

    .request-demo-wrapper {
      border-radius: 8px;
      padding: 50px 20px 50px 20px;
      text-align: center;
      h3{
        width: 100%;
      }
      h6 {
        width: 100%;
      }
    }

    img {
      width: 90%;
      height: auto;
      margin-bottom: 50px;
    }




    p {
      font-size: 14px;
      margin-bottom: 30px;
    }

    .btn-flex-container {
      flex-direction: column;
      justify-content: center;
      margin: auto;
      margin-top: 28px;
    }

    .social-links {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}