@import "../../sassStyles/global";
@import "../../sassStyles/colors";

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.all-products-wrapper .hero-content {
  height: 530px;
  text-align: center;
  flex-direction: column;
  background-image: url(../../../public/backgrounds/about-us-bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 80px;

  .hero-heading {
    font-style: normal;
    font-weight: 700;
  }

  .hero-content-sub-heading {
    margin-top: 24px;
  }

  .hero-sub-heading {
    font-style: normal;
    text-align: center;
    color: $secondaryWhiteColor;
  }
}

.product-wrapper {
  margin-top: 120px;
  padding: 75px 70px 75px 70px;
  border-radius: 10px;
}

.product-btn {
  margin-top: 23px;
}
.btn-learn-more{
  margin-right: 20px;
}

.product-description {
  font-weight: 400;
}

.product-features {
  font-size: 16px;
  font-weight: 700;
  gap: 40px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;

}

.feature-wrapper {
  border-radius: 6px;
  width: 218px;
  height: 218px;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.feature-liner {
  margin-top: 33px;
}

.product-image-container {
  display: flex;
  justify-content: center;
  flex-direction: column;

}

.product-logo {
  margin-bottom: 30px;
}

.last-product {
  margin-bottom: 120px;
}

.sensus-starling-description,
.sensus-vision-description {
  margin-top: 100px;
}

.j-series-description {
  margin-top: 92px;
}

.cables-description {
  margin-top: 72px;
}

.opx-description {
  margin-top: 32px;
}

.sensus-ml-auto {
  margin-left: auto;
}

// APD Section Start
.apd-main-info-width {
  width: 96%;
  font-weight: 700;
}


.apd-slide-2-info-2 {
  width: 96%;
  margin-top: 100px;
}

.apd-slider-1 {
  width: 94%;
  z-index: 3;
  position: relative;
}

.j-series-image {
  width: 87%;
}

.apd-slide-2-info-1 {
  margin-top: 50px;
}

//APD Section End

//Sensus Section Start
.sensus-section {
  margin-top: 150px;
  background-color: $featuresbackgroundColor;
  padding-top: 150px;
  padding-bottom: 100px;
  padding-left: 100px;
  padding-right: 100px;
  border-radius: 10px;
}

.sensus-info-1 {
  margin-bottom: 50px;
}

.sensus-info-2 {
  margin-top: 50px;
}

.sensus-logo-margin {
  margin-bottom: 49px;
}

//Sensus Section End

//J-Series Section Start
.j-series-section {
  padding-top: 150px;
  padding-bottom: 150px;
}

.j-series-info-1 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.j-series-info-2 {
  margin-top: 75px;
}

//J-Series Section End

//OPX Section Start
.opx-section {
  background-color: $featuresbackgroundColor;
  padding-top: 150px;
  padding-bottom: 100px;
  padding-left: 100px;
  padding-right: 100px;
  border-radius: 10px;

}

.opx-info-1 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.opx-info-2 {
  margin-top: 50px;
}

//OPX Section End

//Fiber Optic Cables Start
.fiber-optic-section {
  padding-bottom: 150px;
}

.fiber-optic-section {
  padding-top: 150px;
}

.fiber-optic-info-1 {
  margin-top: 150px;

}

//Fiber Optic Cables End
.sensus-heading {
  font-size: 50px;
}

.j-series-description {
  margin-top: 40px;
}

.cables-logo {
  max-width: 85px;
}

@media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {

  .product-logo {
    margin-bottom: 14px;
  }

  .apd-logo {
    width: 90px;
    height: auto;
  }

  .simplphy-logo {
    max-width: 220px;
    height: auto;
  }

  .sensus-logo {
    max-width: 65px;
  }

  .opx-section,
  .sensus-section {
    padding-left: 50px;
    padding-right: 50px;
  }

  .apd-slide-2-info-2 {
    width: 100%;
  }

  .sensus-info-1 {
    width: 86%;
    margin-left: auto;
  }

  .sensus-info-2 {
    width: 80%;
  }

  .j-series-info-1 {
    width: 97%;
    margin-left: auto;
  }

  .product-wrapper {
    margin-top: 100px;
    padding: 56px 50px 56px 50px;
    border-radius: 10px;
  }

  .feature-wrapper {
    border-radius: 6px;
    width: 190px;
    height: auto;
    padding: 15px;
  }

  .feature-liner {
    // margin: auto;
    margin-top: 20px !important;
  }

  .product-features {
    font-size: 12px;
    gap: 25px;
  }

  .sensus-starling-description,
  .sensus-vision-description {
    margin-top: 75px;
  }

  .icon {
    max-width: 50px;
    height: auto;
  }

  .sensus-heading {
    font-size: 36px;
  }

}

@media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
  .product-logo {
    margin-bottom: 15px;
  }

  .apd-logo {
    width: 70px;
    height: auto;
  }

  .simplphy-logo {
    max-width: 185px;
    height: auto;
  }

  .sensus-logo {
    max-width: 55px;
  }

  .opx-section,
  .sensus-section {
    padding-left: 40px;
    padding-right: 40px;
  }

  .apd-slide-2-info-2 {
    width: 100%;
  }

  .sensus-info-1 {
    width: 86%;
    margin-left: auto;
  }

  .sensus-info-2 {
    width: 80%;
  }

  .j-series-info-1 {
    width: 97%;
    margin-left: auto;
  }

  .product-wrapper {
    margin-top: 100px;
    padding: 45px 40px 45px 40px;
    border-radius: 6px;
  }

  .feature-wrapper {
    border-radius: 4px;
    width: 190px;
    height: auto;
    padding: 10px;
  }

  .feature-liner {
    // margin: auto;
    margin-top: 20px !important;
  }

  .product-features {
    font-size: 10px;
    gap: 20px;
  }

  .sensus-starling-description,
  .sensus-vision-description, .cables-description {
    margin-top: 50px;
  }
  .cables-description {
    margin-top: 35px;
  }

  .icon {
    max-width: 38px;
    height: auto;
  }

  .sensus-heading {
    font-size: 30px;
  }
  .cables-logo {
    max-width: 70px;
}
.product-features {
  margin-top: 30px;
}


}

@media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
  .all-products-wrapper .hero-content {
    height: 330px;
  }
  .product-logo {
    margin-bottom: 10px;
  }

  .apd-logo {
    width: 60px;
  }

  .simplphy-logo {
    max-width: 155px;
  }

  .sensus-logo {
    max-width: 45px;
  }

  .apd-slide-2-info-2 {
    width: 100%;
  }

  .sensus-info-1 {
    width: 86%;
    margin-left: auto;
  }

  .sensus-info-2 {
    width: 80%;
  }

  .j-series-info-1 {
    width: 97%;
    margin-left: auto;
  }

  .product-wrapper {
    margin-top: 50px;
    padding: 35px 30px 35px 30px;
    border-radius: 6px;
  }

  .feature-wrapper {
    border-radius: 4px;
    width: 190px;
    height: auto;
    padding: 9px;
  }

  .feature-liner {
    // margin: auto;
    margin-top: 20px !important;
  }

  .product-features {
    font-size: 8px;
    gap: 20px;
  }

  .sensus-starling-description,
  .sensus-vision-description, .cables-description {
    margin-top: 30px;
  }
  .cables-description {
    margin-top: 25px;
  }

  .icon {
    max-width: 30px;
    height: auto;
  }

  .sensus-heading {
    font-size: 26px;
  }
  .cables-logo {
    max-width: 60px;
}
.product-features {
  margin-top: 20px;
}

}

@media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
  .all-products-wrapper .hero-content {
    height: 350px;
  }
  .product-logo {
    margin-bottom: 10px;
  }

  .apd-logo {
    width: 45px;
  }

  .simplphy-logo {
    max-width: 120px;
  }

  .sensus-logo {
    max-width: 35px;
  }

  .apd-slide-2-info-2 {
    width: 100%;
  }

  .sensus-info-1 {
    width: 86%;
    margin-left: auto;
  }

  .sensus-info-2 {
    width: 80%;
  }

  .j-series-info-1 {
    width: 97%;
    margin-left: auto;
  }

  .product-wrapper {
    margin-top: 45px;
    padding: 30px 20px 30px 20px;
    border-radius: 6px;
  }

  .feature-wrapper {
    border-radius: 4px;
    width: 190px;
    height: auto;
    padding: 9px;
  }

  .feature-liner {
    // margin: auto;
    margin-top: 20px !important;
  }

  .product-features {
    font-size: 8px;
    gap: 20px;
  }

  .sensus-starling-description,
  .sensus-vision-description, .cables-description {
    margin-top: 30px;
  }
  .cables-description {
    margin-top: 25px;
  }

  .icon {
    max-width: 30px;
    height: auto;
  }

  .sensus-heading {
    font-size: 26px;
  }
  .cables-logo {
    max-width: 60px;
}
.product-features {
  margin-top: 20px;
}

}
@media (max-width: $midSmallDevice) and (min-width: $maxTabDevice) {
  .product-features {
    margin-bottom: 0 !important;
  }
}

@media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
  .hero-sub-heading {
    width: 73%;
    line-height: 150%;
  }

  .hero-content-sub-heading {
    margin-top: 33px;
    width: 80%;
  }

  .product-wrapper {
    margin-top: 50px;
    padding: 30px 14px 20px 14px;
    border-radius: 6px;
  }
  .product-btn{
    margin-bottom: 30px;
  }

  .apd-logo {
    max-width: 60px;
  }

  .simplphy-logo {
    max-width: 160px;
  }

  .sensus-logo {
    max-width: 65px;
  }

  .sensus-heading {
    font-size: 22px;
  }

  .product-features {
    font-size: 7px;
    font-weight: 700;
    gap: 9px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .feature-wrapper {
    border-radius: 6px;
    width: 32%;
    height: 86px;
    padding: 6px;
  }

  .icon {
    width: 20px;
    height: auto;
  }

  .feature-liner {
    margin-top: 14px;
  }

  .apd-vision-wrapper,
  .sensus-starling-wrapper, .j-series-wrapper{
    flex-direction: column-reverse;
  }

  .sensus-starling-description,
  .sensus-vision-description {
    margin-top: 30px;
  }
  .cables-description {
    margin-top: 30px;
}

  .last-product {
    margin-bottom: 50px;
  }
  .cables-logo {
    max-width: 70px;
}

}