.modal-content.video-player {
    border-radius: 12px;
    background-color: transparent;
}

.modal-dialog.video-modal-dialogue, .modal-content.video-player {
    max-width: var(--bs-modal-width) !important;
    iframe {
        max-width: 100% !important;
        max-height: 100% !important;
    }
}