@import "../../sassStyles/colors";
@import "../../sassStyles/variables";


.footer {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    height: auto;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 70px;

    .footer-logo {
        margin-bottom: 41px;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        margin-top: 28px;
        line-height: 170%;
        text-align: center;
        width: 50%;
    }
}

.legal {
    display: flex;
    flex-direction: row;
    opacity: 0.6;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    align-items: center;
    bottom: 0;
    padding-bottom: 20px;
    padding-bottom: 20px;
}

.social-links {
    display: inline-block;

    a {
        margin-right: 0.6875rem;
    }
}

.hr-footer {
    opacity: 0.1;
    border: 1px solid black;
}

footer {
    background-color: black;
    z-index: 2;
    position: relative;
}

.link,
link:hover {
    color: black !important;
    text-decoration: none;
}

.footer-links {
    gap: 100px;
    font-weight: 600;
    margin-top: 20px;
    font-size: 18px;
}

@media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
    .footer {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .footer p {
        font-size: 14px;
        width: 57%;
    }

    .footer-links {
        gap: 80px;
        font-weight: 600;
        margin-top: 20px;
        font-size: 16px;
    }
}


@media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
    .footer-padding {
        padding-top: 4.375rem;
    }

    .footer-logo {
        margin-bottom: 26px;
        width: 50%;

    }

    .footer-item {
        font-size: 0.8rem;

    }

    .footer-heading-font {
        font-size: 18px;
    }

    .footer-contact-heading {
        font-size: 32px;
    }

    .footer .footer-contact-info {
        font-size: 0.75rem;
        width: 100%;
    }

    .items-list {
        margin-bottom: 3.0rem;
    }

    .legal span {
        font-size: 10px;
    }
}

@media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
    .footer-logo {
        max-width: 126px;
    }

    .footer-item {
        font-size: 0.625rem;
    }

    .footer-heading-font {
        font-size: 0.812rem;
    }

    .footer-contact-heading {
        font-size: 1.5rem;
    }

    .footer {
        height: 200px;

        p {
            font-size: 14px;
            width: 68%;
            margin-top: 20px;
        }

        .footer-links {
            font-size: 12px;
            gap: 40px;
        }

    }


    .footer-contact-info {
        font-size: 0.625rem !important;
    }

    .footer .footer-contact-info {
        width: 100%;
    }

    .legal {

        .footer-text,
        .link {
            font-size: 0.75rem;
        }
    }

    .company-links {
        padding-left: 0;
    }
}

@media (min-width: $minTabDevice) and (max-width: $maxTabDevice) {
    .footer p {
        width: 75%;
    }

    .footer .footer-contact-info {
        font-size: 1rem;
        width: 100%;
    }

    .items-list {
        margin-top: 70px;
    }

    .legal {

        .footer-text,
        .link {
            font-size: 0.75rem;
        }
    }

    .company-links {
        padding-left: 0;
    }


    .footer-container {
        flex-wrap: wrap;

        .six-col {
            width: 50%;
        }

        .four-col {
            width: 33.3333333%;
        }
    }

}

@media (max-width: $maxTabDevice) {
    .tablet-contact {
        display: block !important;
    }

    .tablet-bottom-contact {
        display: none;
    }

    .items-list {
        margin-top: 50px;
        margin-bottom: 0;
    }
}

@media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
    .footer {
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;

        p {
            width: 85%;
            font-size: 12px;
        }

        .footer-links {
            gap: 20px;
            margin-top: 10px;
            font-size: 12px;
        }

    }
    .social-icon{
        width: 16px;
        height: 16px;

    }
    .social-links a {
        margin-right: 7px;
    }

    .legal {
        flex-direction: column;
    }
}