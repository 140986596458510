@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.sitemap-wrapper {
    margin-top: 200px;
    // margin-bottom: 90px;
    position: relative;
    .divider-margin{
        margin-bottom: 150px;
        margin-top: 80px;
    }

    h3{
        margin-bottom: 50px;
    }
    .privacy-policy-right-gradient {
        width: 700px;
        height: 700px;
        background: $bgGradient;
        transform: translate3d(0, 0, 0);
        filter: blur(12.5rem);
        top: 0;
        right: 0;
        position: absolute;
    }

    .privacy-policy-left-gradient {
        width: 425px;
        height: 425px;
        background: $bgGradient;
        transform: translate3d(0, 0, 0);
        filter: blur(12.5rem);
        top: 20%;
        left: 0;
        position: absolute;
    }

    .sitemap-list {
        position: relative;
        z-index: 3;
        list-style: none !important;
        padding-left: 0 !important;
        margin-bottom: 160px;

        li {
            margin-bottom: 30px;
        }
    }
    .sitemap-link:hover{
        color: $primaryGreenColor;
    }
    @media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
        .col-lg-6{
            width: 66% !important;
        }
    }
    @media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
        .col-sm-12{
            width: 100% !important;
        }
    }
    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        .divider-margin{
            margin-bottom: 50px;
            margin-top: 50px;
        }
        .privacy-policy-left-gradient,
            .privacy-policy-right-gradient {
                display: none;
            }
        }
        .home-list, .sitemap-list {
            margin-bottom: 70px;
        }
        h2{
            margin-bottom: 40px;
        }
        h5{
            font-size: 20px;
        }
}