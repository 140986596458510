

@font-face {
  font-family: "Poppins-Regular";
  src: url("../../public/fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../../public/fonts/Poppins/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins-Semi-Bold";
  src: url("../../public/fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "DMSans-Bold";
  src: url("./../../public/fonts/DMSans/DMSans-Bold.ttf") format('ttf');
}

@font-face {
  font-family: "DMSans-BoldItalic";
  src: url("./../../public/fonts/DMSans/DMSans-BoldItalic.ttf") format('ttf');
}

@font-face {
  font-family: "DMSans-Italic";
  src: url("./../../public/fonts/DMSans/DMSans-Italic.ttf") format('ttf');
}

@font-face {
  font-family: "DMSans-Medium";
  src: url("./../../public/fonts/DMSans/DMSans-Medium.ttf") format('ttf');
}

@font-face {
  font-family: "DMSans-Regular";
  src: url("./../../public/fonts/DMSans/DMSans-Regular.ttf") format('ttf');
}

.dmsans-italic {
  font-family: 'DMSans-Italic', sans-serif;
}

.dmsans-regular {
  font-family: 'DMSans-Regular', sans-serif;
}
@font-face {
  font-family: "DMSans-Bold";
  src: url("./../../public/fonts/DMSans/DMSans-Bold.ttf") format("ttf");
}

@font-face {
  font-family: "DMSans-BoldItalic";
  src: url("./../../public/fonts/DMSans/DMSans-BoldItalic.ttf") format("ttf");
}

@font-face {
  font-family: "DMSans-Italic";
  src: url("./../../public/fonts/DMSans/DMSans-Italic.ttf");
}

@font-face {
  font-family: "DMSans-Medium";
  src: url("./../../public/fonts/DMSans/DMSans-Medium.ttf") format("ttf");
}

@font-face {
  font-family: "DMSans-Regular";
  src: url("./../../public/fonts/DMSans/DMSans-Regular.ttf");
}

.dmsans-italic {
  font-family: "DMSans-Italic", sans-serif;
}

.dmsans-regular {
  font-family: "DMSans-Regular", sans-serif;
}

.poppins-regular {
  font-family: "Poppins-Regular", sans-serif;
}

.poppins-bold {
  font-family: "Poppins-Bold", sans-serif;
}

.poppins-semi-bold {
  font-family: "Poppins-Semi-Bold", sans-serif;
}