@import "colors";
@import "fonts";
@import "variables";

body {
  font-size: 16px;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bodybackgroundColor;
}

.hero-content {
  height: 100vh;
}

.view-apd-btn img {
  width: auto !important;
}

//Typography
h1 {
  font-size: 70px;
  line-height: 90px;
  margin: 0;

  span {
    font-size: 70px;
  }

  .registered-R {
    font-size: 0.5em;
    top: -0.7em;
    left: -0.1em;

  }
}

h2 {
  font-size: 60px;
  line-height: 76px;

  span {
    font-size: 60px;

  }

  .registered-R {
    font-size: 0.5em;
    top: -0.7em;
    left: -0.1em;
    position: relative;

  }
}

h3 {
  font-size: 54px;
  line-height: 66px;

  span {
    font-size: 54px;
    line-height: 66px;
  }

  .registered-R {
    font-size: 0.7em;

  }
}

h4 {
  font-size: 34px;
  line-height: 150%;

  span {
    font-size: 34px;
    line-height: 36px;
  }
}

h5 {
  font-size: 1.75rem;
  line-height: 42px;

  span {
    font-size: 1.75rem;
    line-height: 42px;
  }

  .registered-R {
    font-size: 0.6em;
    top: -0.7em;
    left: -0.1em;
    position: relative;

  }
}

h6 {
  font-size: 22px;
  line-height: 148%;

  span {
    font-size: 22px;
  }
}

p {
  font-size: 18px;
  line-height: 24px;
}

span {
  font-size: 16px;
  line-height: 1.5rem;
}

//Text Colors

.text-primary-green {
  color: $primaryGreenColor;
}

.text-primary-red {
  color: $primaryRedColor;
}

.text-secondary-white {
  color: $secondaryWhiteColor;
}

.text-white {
  color: $colorWhite;
}

.text-purple {
  color: $bgPurpleColor;
}


.text-green {
  color: $secondaryBtnTextColor;
}

.text-footer-color {
  color: $footerTextColor;
}

//Text Alignment

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

// Text Gradient
.text-gradient {
  background: $textGradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

//Section Description

.section-description {
  font-weight: 400;
  font-size: 2.375rem;
  line-height: 116.5%;

  span {
    font-size: 2.375rem;
  }
}

//Z Indexes

.z-index-1 {
  z-index: 1;
  position: relative;
  width: 80%;
  margin: auto;
}

.z-index-2 {
  z-index: 2;
}

//Background Colors

.bg-blue {
  background-color: $bodybackgroundColor;
}

.bg-white {
  background-color: $colorWhite;
}

.bg-demo {
  background-color: $demobackgroundColor;
}

.bg-blog {
  background-color: $primaryBtnColor;
}

.bg-features {
  background-color: $featuresbackgroundColor;
}

.bg-support {
  background-color: $bgSupport;
}

.bg-black {
  background-color: #000000;
}

.bg-pink {
  background-color: $secondaryPinkColor;
}

.bg-purple {
  background-color: $bgPurpleColor;
}

.bg-red {
  background-color: $primaryRedColor;
}

.badge {
  display: inline-block;
  padding: 0.5em 1em;
  font-size: 0.75em;
  font-weight: bold;
  color: white;
  background-color: $primaryRedColor;
  border-radius: 0.25em;
  margin-bottom: 1rem;
  text-transform: uppercase;
  min-width: 4.375rem;
}

//Button Styling and States

.btn {
  background-color: $primaryRedColor;

}

.btn.secondary-btn {
  background-color: $btnSecondaryBg;
  border-color: $btnSecondaryBg;
}

.btn.secondary-light-border {
  border-color: $colorWhite;
}

.btn.secondary-btn:hover {
  background-color: $primaryRedColor;
  color: $hoverBtnColor;
}

.btn:hover {
  background-color: $secondaryWhiteColor;
  border-color: $primaryRedColor;
}

.primary-btn:hover {
  color: $primaryRedColor !important;
}

.primary-btn {
  font-style: normal;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  height: auto;
  line-height: 1.28rem;
  border-radius: 0.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  text-decoration: none;
  font-family: 'DMSans-Regular';
}

.secondary-btn {
  font-style: normal;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  height: auto;
  line-height: 1.28rem;
  border-radius: 0.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  text-decoration: none;
  border: 1px solid;
  font-family: 'DMSans-Regular';
}

.sup {
  font-size: 0.6em;

}

.sensus-trademark {
  font-size: .4em !important;
  top: -0.8em;
  left: -0.3em;
}

.sensus-heading-trademark {
  font-size: 1.2rem;
  top: -1.3em;
  left: -0.5em;
}

//Labels
.label {
  border-radius: 100px;
  background-color: $primaryRedColor;
  max-width: 140px;
  margin: auto;
  margin-bottom: 40px;
}

@media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {

  .primary-btn,
  .secondary-btn {
    width: 220px;
    height: auto;
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    padding-top: 9px;
    padding-bottom: 9px;
    margin-right: 0 !important;
  }
}



.text-url {
  text-decoration: none;
  color: $primaryRedColor;
}

.text-url:hover {
  color: $btnSecondaryBg;
}
.resource-text-url{
  text-decoration: none;
  color: $btnSecondaryBg;
}
.resource-text-url:hover{
  color: $primaryRedColor;
}



// Border Colors
.border-secondary-color {
  border-color: $borderSecondayBtn;
}

.border-secondary-color:hover {
  border-color: $hoverBtnColor;
}

//Media Queries
//Container
@media (min-width: 1600px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100%
  }

  .container,
  .container-lg {
    padding-left: 9.375rem;
    padding-right: 9.375rem;
    max-width: 1920px;
  }

}

@media (min-width: $minSmallDevice) and (max-width: 992px) {
  // .container {
  //   padding-left: 72px !important;
  //   padding-right: 72px !important;
  // }
}

//Positions
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

//Font Weights

.font-weight-700 {
  font-weight: 700;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-400 {
  font-weight: 400;
}

//widths
.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}
.w-60 {
  width: 60%;
}


.w-90 {
  width: 90%;
}


@media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
  h1 {
    font-size: 56px;
    line-height: 74px;
    margin: 0;

    span {
      font-size: 56px;
      line-height: 74px;
    }
  }

  h2 {
    font-size: 46px;
    line-height: 120%;

    span {
      font-size: 46px;
    }
  }

  h3 {
    font-size: 38px;
    line-height: 47px;

    span {
      font-size: 38px;
    }
  }

  h4 {
    font-size: 25px;
    line-height: 150%;

    span {
      font-size: 25px;
    }
  }

  h5 {
    font-size: 24px;
    line-height: 150%;

    span {
      font-size: 24px;
    }
  }

  h6 {
    font-size: 20px;
    line-height: 140%;

    span {
      font-size: 20px;
    }
  }

  p {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  span {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .container,
  .container-lg {
    max-width: 1200px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

}


@media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
  h1 {
    font-size: 44px;
    line-height: 110% !important;
    // margin: 0;

    span {
      font-size: 44px;
    }
  }

  h2 {
    font-size: 34px;
    line-height: 120%;

    span {
      font-size: 34px;
    }
  }

  h3 {
    font-size: 32px;
    line-height: 120%;

    span {
      font-size: 32px;
      line-height: 120%;
    }
  }

  h4 {
    font-size: 21px;
    line-height: 150%;

    span {
      font-size: 21px;
    }
  }

  h5 {
    font-size: 18px;
    line-height: 150%;

    span {
      font-size: 18px;
    }
  }

  h6 {
    font-size: 14px;
    line-height: 120%;

    span {
      font-size: 14px;
    }
  }

  p {
    font-size: 13px;
    line-height: 1.5rem;
  }

  span {
    font-size: 13px;
    line-height: 1.5rem;
  }

  .secondary-btn,
  .primary-btn {
    font-size: 12px;
    line-height: 15px;
    border-radius: 0.25rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-top: 0.45rem;
    padding-bottom: 0.45rem;
  }

  .container,
  .container-lg {
    max-width: 1000px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

}

@media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
  h1 {
    font-size: 40px;
    line-height: 100%;
    margin: 0;

    span {
      font-size: 40px;
    }
  }

  h2 {
    font-size: 34px;
    line-height: 120%;

    span {
      font-size: 34px;
    }
  }

  h3 {
    font-size: 26px;
    line-height: 120%;

    span {
      font-size: 26px;
      line-height: 120%;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 150%;

    span {
      font-size: 18px;
    }
  }

  h5 {
    font-size: 0.938rem;
    line-height: 150%;

    span {
      font-size: 0.938rem;
    }
  }

  h6 {
    font-size: 14px;
    line-height: 120%;

    span {
      font-size: 14px;
    }
  }

  p {
    font-size: 12px;
    line-height: 15px;
  }

  span {
    font-size: 0.75rem;
    line-height: 1.5rem;
  }

  .secondary-btn,
  .primary-btn {
    font-size: 12px;
    line-height: 15px;
    border-radius: 0.25rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-top: 0.45rem;
    padding-bottom: 0.45rem;
  }
}

@media (min-width: $midSmallDevice) and (max-width: $maxSmallDevice) {

  .container,
  .container-lg {
    max-width: 850px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (min-width: $minTabDevice) and (max-width: $maxTabDevice) {
  h1 {
    font-size: 34px;
    line-height: 120%;
    margin: 0;

    span {
      font-size: 34px;
    }
  }

  h2 {
    font-size: 1.875rem;
    line-height: 120%;

    span {
      font-size: 1.875rem;
    }
  }

  h3 {
    font-size: 1.5rem;
    line-height: 120%;

    span {
      font-size: 1.5rem;
      line-height: 120%;
    }
  }

  h4 {
    font-size: 1.125rem;
    line-height: 150%;

    span {
      font-size: 1.125rem;
    }
  }

  h5 {
    font-size: 0.875rem;
    line-height: 150%;

    span {
      font-size: 0.875rem;
    }
  }

  h6 {
    font-size: 12px;
    line-height: 120%;

    span {
      font-size: 12px;
    }
  }

  p {
    font-size: 10px;
    line-height: 13px;
  }

  span {
    font-size: 10px;
    line-height: 13px;
  }
}

@media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
  h1 {
    font-size: 32px;
    line-height: 38px;
    margin: 0;

    span {
      font-size: 32px;
    }
  }

  h2 {
    font-size: 24px;
    line-height: 27px;

    span {
      font-size: 24px;
      line-height: 27px;
    }
  }

  h3 {
    font-size: 22px;
    line-height: 120%;

    span {
      font-size: 22px;
      line-height: 120%;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 27px;

    span {
      font-size: 18px;
      line-height: 27px;
    }
  }

  h5 {
    font-size: 14px;
    line-height: 150%;

    span {
      font-size: 14px;
      line-height: 150%;
    }
  }

  h6 {
    font-size: 14px;
    line-height: 125%;

    span {
      font-size: 14px;
      line-height: 125%;
    }
  }

  p {
    font-size: 12px;
    line-height: 16px;
  }

  span {
    font-size: 10px;
    line-height: 16px;
  }

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}