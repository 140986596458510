@import "../../sassStyles/colors";
@import "../../sassStyles/variables";
// .glc-request-demo {
//     padding-top: 150px;
// }
.eula-wrapper {
    margin-top: 200px;
    // margin-bottom: 200px;
    position: relative;

    .corporation-name {
        margin-bottom: 30px;
    }

    .eula-description {
        width: 70%;
        margin: auto;
        text-align: center;
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .faqs-accordion {
        padding-bottom: 80px;

        h2 {
            margin-bottom: 6.625rem;

        }

        .accordion-item {

            border-bottom: 0.031rem solid $primaryRedColor;
            padding: 2rem;
            position: relative;

        }

        .item-border-right {
            border-right: 0 !important;

        }

        .border-top-solid {
            width: 100%;
            border-top: 9px solid $primaryRedColor;
            border-radius: 4px 4px 0px 0px;
            position: absolute;
            top: -1px;
            left: 0;
        }


        .item-gradient {
            .accordion-button::after {
                transform: rotate(180deg);
            }
        }

        .accordian-header {
            background-color: $bodybackgroundColor;
        }

        .accordion-button:focus {
            box-shadow: none;
            border-color: rgba(0, 0, 0, .125);
        }

        .accordion-button {
            span {
                width: 70%;
                line-height: 150%;
            }
        }

        .accordion-body {
            width: 80%;
        }

        .accordion-button,
        .accordion-button:not(.collapsed) {
            color: var(--bs-accordion-active-color);
            background-color: $bodybackgroundColor;
            box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
            background: transparent;
            filter: drop-shadow(0 0.25rem 2.5rem rgba(0, 0, 0, 0.12));
            font-size: 1.5rem;
            padding: 0;
            padding-bottom: 1.563rem;
            --bs-accordion-border-color: transparent;
            --bs-accordian-border: 0;

        }

        .accordion-button:not(.collapsed) {
            .accordian-heading-green-part {
                color: $primaryGreenColor;
            }
        }

        .accordion-item:first-of-type .accordion-button {
            border: none;
        }


        .accordion {
            border: none;
        }

        .accordion-body {
            padding: 0;
            font-size: 1rem;
        }

        .accordion {
            --bs-accordion-border-color: transparent;
            --bs-accordian-border: 0;
        }

        .accordion-image {
            padding-left: 7.875rem;
        }

        .accordion-button::after {
            background-image: url("../../../public/solutions/datacenter-colocation-assets/icon.svg");
            background-position: center;
            transform: rotate(0deg);
        }

        .accordion-button::not(.collapsed)::after {
            content: "";
            background-image: url("../../../public/solutions/datacenter-colocation-assets/active-icon.svg");
            background-repeat: no-repeat;
            background-size: 1.25rem;
            transition: transform .2s ease-in-out;
            background-position: center;
        }
    }

    .accordion-button,
    .features-accordion .accordion-button:not(.collapsed) {
        padding-bottom: 0 !important;
    }

    @media (min-width: $minMediumDevice) and (max-width: $maxLargeDevice) {
        .eula-description {
            width: 90%;
            margin: auto;
            text-align: center;
            margin-top: 60px;
            margin-bottom: 60px;
        }

        .faqs-accordion {
            padding-bottom: 60px;
        }

        .accordion-item {
            padding: 2rem;
        }
    }

    @media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
        h1 {
            margin-bottom: 90px;
        }

        .eula-description {
            width: 85%;
            margin-top: 75px;
            margin-bottom: 75px;
        }

        .faqs-accordion {
            padding-bottom: 75px;
            padding-top: 75px;
        }

        .corporation-name {
            margin-bottom: 35px;
        }

        .accordion-button::after {
            background-size: 12px;
        }

        .faqs-accordion .accordion-body {
            width: 90%;
        }
        .accordion-item {
            padding: 1.5rem;
        }


    }

    @media (min-width: $minTabDevice) and (max-width:$midSmallDevice) {
        .corporation-name {
            margin-bottom: 30px;
        }
        h1 {
            margin-bottom: 65px;
        }
        .eula-description {
            width: 85%;
            margin-top: 75px;
            margin-bottom: 75px;
        }

        .faqs-accordion {
            padding-bottom: 75px;
            padding-top: 75px;
        }
        .accordion-button{
            font-size: 18px !important;
        }
        .accordion-button::after {
            background-size: 12px !important;
        }
        .accordion-item {
            padding: 1.5rem !important;
        }


    }
    @media (max-width:$maxMobileDevice) {
        margin-top: 150px;
        // -webkit-transform: translate3d(0, 0, 0);
        .corporation-name {
            margin-bottom: 20px;
        }
        h1 {
            margin-bottom: 40px;
        }
        .eula-description {
            width: 90%;
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .faqs-accordion {
            padding-bottom: 60px;
            padding-top: 60px;
        }
        .accordion-button{
            font-size: 18px !important;
        }
        .accordion-button::after {
            background-size: 12px !important;
        }
        .accordion-item {
            padding: 1.25rem !important;
        }



    }


}