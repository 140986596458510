@import "../../sassStyles/global";
@import "../../sassStyles/colors";

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.all-solutions-wrapper .hero-content {
  height: 530px;
  text-align: center;
  flex-direction: column;
  background-image: url(../../../public/backgrounds/enterprise-solutions-bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .hero-content-sub-heading {
    margin-top: 20px;
  }

  .hero-sub-heading {
    text-align: center;
    color: $secondaryWhiteColor;
  }
}

.solution-wrapper {
  margin-top: 80px;
  border-radius: 10px;
  .row{
    margin-left: 0 !important;
    margin-right: 0 !important;

  }
}

.solution-btn {
  margin-top: 23px;
}

.solution-heading {
  margin-bottom: 50px;
}

.image-padding-right {
  padding-right: 70px;
}

.image-padding-left {
  padding-left: 70px;
}

.container-image {
  position: relative;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;

  .half-background-right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .half-background-left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.solution-image {
  position: relative;
  width: 85%;

}

.container-content-left {
  padding: 75px 0 75px 70px;
}

.container-content-right {
  padding: 75px 70px 75px 0;
}

.last-solution {
  margin-bottom: 120px;
}