@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.more-products-container {
    padding-bottom: 7rem;

    .more-products-heading {
        padding-bottom: 4.188rem;
        padding-top: 4.188rem;
    }

    .slick-dots li.slick-active button:before {
        opacity: 1;
        color: $primaryRedColor;

    }

    .slick-dots li button:before {
        font-family: 'slick';
        font-size: 5rem;
        line-height: 1.25rem;
        position: absolute;
        top: 0.938rem;
        left: 0;
        width: 2.5rem;
        height: 2.5rem;
        content: '-';
        text-align: center;
        opacity: .25;
        color: #FFFFFF;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .slide-item {
        border-radius: 12px;
        width: 80% !important;
        margin: auto;
        padding-top: 80px;
        padding-bottom: 80px;
        padding-left: 60px;
        padding-right: 40px;
        display: block !important;
    }

    .more-products-gradient {
        position: absolute;
        background: $bgGradient;
        transform: translate3d(0, 0, 0);
        width: 200px;
        height: 200px;
        filter: blur(9rem);
        right: 22%;
        bottom: 10%;
        top: 10%;
        margin: auto;
    }

    .slide-item {
        img {
            width: auto;
            height: 270px;
        }

    }

    .one-liner {
        margin-top: 12px;
        margin-bottom: 30px;
        font-size: 18px;

        span {
            font-size: 18px;
        }

    }

    @media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
        .slide-item img {
            width: auto;
            height: 260px;

        }

        .one-liner {
            font-size: 16px;
            margin-top: 12px;
            margin-bottom: 25px;

            span {
                font-size: 16px;
            }
        }


    }

    @media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
        .slide-item img {
            height: 220px;
            width: auto;

        }
        .one-liner {
            margin-top: 12px;
            margin-bottom: 25px;
            font-size: 14px;

            span {
                font-size: 14px;
            }

        }
    }

    @media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
        .slide-item {
            width: 90% !important;
            padding-top: 80px;
            padding-bottom: 80px;
            padding-left: 30px;
        }

        .slide-item img {
            height: 200px;
            width: auto;

        }
        .one-liner {
            margin-top: 12px;
            margin-bottom: 20px;
            font-size: 12px;

            span {
                font-size: 12px;
            }

        }
    }

    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        .slide-item {
            width: 90% !important;
            padding: 2rem;
            text-align: center;

            img {
                display: none;
            }

            p {
                width: 60%;
                margin: auto;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
            .one-liner {
                margin-top: 12px;
                margin-bottom: 20px;
                font-size: 12px;

                span {
                    font-size: 12px;
                }

            }
        }
    }

    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        .more-products-heading {
            padding-bottom: 50px;
            padding-top: 50px;
            width: 85%;
            margin: auto;
            line-height: 110%;
        }

        .slide-item {
            width: 85% !important;
            padding: 30px;
            text-align: center;

            img {
                display: none;
            }
        }
        .one-liner {
            margin-top: 12px;
            margin-bottom: 20px;
            font-size: 12px;

            span {
                font-size: 12px;
            }

        }
        .slick-dots {
            position: absolute;
            bottom: 19px !important;
        }
    }
}