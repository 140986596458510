@import "../../sassStyles/colors";
@import "../../sassStyles/variables";
@import "../../sassStyles/fonts";

.megamenu {
  position: static;
}
.mega-menu-mobile {
  .accordion {
    --bs-accordion-bg: transparent;

    .accordian-collapse {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      will-change: height;

      &.collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        -webkit-transition: height 0.1s ease;
        transition: height 0.1s ease;
      }
    }
    .accordion-button::after {
      background-size: 12px;
      background-position: center;
    }
  }
}


.nav-image {
  margin-bottom: 3rem;
  img {
    border-radius: 14px;
  }
}

.follow-text {
  font-size: 22px;
  line-height: 130%;
  opacity: 0.6;
}

.nav {
  background-color: $primaryDarkBlueColor;
}

.navbar-shadow {
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
}

.links-row {
  height: 100%;
}

.nav-container {
  height: 100vh;
  min-height: -webkit-fill-available;
  position: relative;
}

.navbar-nav {
  display: inline-flex;
  align-items: center;

  .top-nav-option {
    i {
      transition: transform 0.3s ease;
    }

    &.text-primary-green {
      i {
        transform: rotateZ(180deg);
      }
    }
  }
}

.soci-mobile {
  .cross-icon {
    font-size: 1.75rem;
  }
}

.nav-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.top-nav-wrapper {
  .nav-item {
    font-weight: 500;
    line-height: 2.25rem;
    border-radius: 0.125rem;
    text-align: center;
    margin-left: 3.125rem;
    margin-right: 0;
    font-family: 'DMSans-Regular';
    .nav-link-style{
      text-decoration: none;
    }
    .nav-link-style:hover{
      color: $primaryRedColor !important;
      font-weight: 700;

    }
  }
}

.menu-transition-enter {
  opacity: 0;
  transform: translateY(30px);
}

.menu-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.menu-transition-exit {
  opacity: 1;
  transform: translateY(0);
}

.menu-transition-exit-active {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 300ms, transform 300ms;
}

.glc-logo {
  max-width: 11.125rem;
}

.nav-main-list .nav-item {
  font-size: 40px;
  line-height: 100%;
  margin-bottom: 56px;
  text-align: left;
  z-index: 2;
  position: relative;
}

.nav-bottom-gradient {
  position: absolute;
  width: 600px;
  height: 300px;
  background: $bgGradient;
  transform: translate3d(0, 0, 0);
  filter: blur(10.5rem);
  top: 80%;
  left: 40%;
  z-index: 2;
}

.mega-menu {
  padding-top: 5rem;
}

.padding-mega-links {
  padding-top: 80px;
}

.padding-links {
  padding-top: 100px;
}

.page-link {
  font-size: 22px;
  font-weight: 400 !important;
}
.page-link:hover{
  color: $primaryGreenColor;
}

.nav-link:hover, .page-link:hover {
  color: $hoverBtnColor;
  cursor: pointer;
}

.nav-main-list .page-item {

  margin: auto;
  margin-bottom: 0;

}

.hamburger-container {
  display: flex;
  gap: 24px;
  align-items: center;
  h5{
    margin-bottom: 0.26em;
  }
}

.nav-container .row {
  align-items: center;
}

.mega-menu-mobile {
  display: none;
}
.hamburger-container{
  display: none;
}
.join-us-btn{
  background-color: transparent;
  border: 1px solid $primaryBtnColor;
  padding: 10px 68px 10px 68px;
  border-radius: 4px;
  max-width: 200px;
  margin-left: 1rem;
  a{
    text-decoration: none;
    color: $colorWhite;
    font-weight: 700 !important;
  }

}
.join-us-btn:hover{
  background-color: $primaryBtnColor;
}
.nav-demo{
  height: 60px !important;
}
.nav-demo-btn{
  margin-left: 20px !important;
  background-color: transparent !important;
  border: 1px solid #48B74B;
  border-radius: 4px;
  width: 150px;
  margin-left: 1rem;
  font-weight: 700;
  span{
    font-size: 12px !important;
  }


}
.nav-btn-width{
  max-width: 186px;
}
.nav-demo-btn:hover{
  background: $primaryBtnColor !important;

}
@media (min-width: $minLargeDevice) {
  .top-nav-wrapper {
      .nav-item {
        font-size: 18px;
      }
    }
}
@media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
  .mega-menu {
    padding-top: 4rem;
  }

  .nav-selected-image {
    width: 100%;
  }
  .navbar{
    height: 68px;
  }
  .fm-logo{
   max-width: 110px;
  }
  .top-nav-wrapper .nav-item {
    font-size: 18px;
}
}
@media (max-width: $minMediumDevice) and (min-width: $midSmallDevice){
  .top-nav-wrapper {
      .nav-item {
        font-size: 0.843rem;
      }
    }

}
@media (max-width: $midSmallDevice) {
  .hamburger-container{
    display: block;
  }

  .mega-menu-desktop {
    display: none;
  }

  .mega-menu-mobile {
    display: block;
    height: 100vh;
  }
   .nav-list, .btn-desktop-demo{
    display: none !important;
  }
  .accordion-item {

    // border-bottom: 0.031rem solid $primaryRedColor;
    padding: 2rem 0.75rem 0.75rem 2rem;
    position: relative;

  }

  .item-border-right {
    border-right: 0 !important;

  }

  .border-top-solid {
    width: 100%;
    border-top: 9px solid $primaryGreenColor;
    border-radius: 4px 4px 0px 0px;
    position: absolute;
    top: -1px;
    left: 0;
  }


  .item-gradient {
    border: 0.031rem solid;
    border-image-source: linear-gradient(to left top, rgba(73, 200, 73, 1), rgba(73, 200, 73, 0));
    border-image-slice: 1;

  }

  .accordian-header {
    background-color: $bodybackgroundColor;
  }

  .accordion-button:focus {
    box-shadow: none;
    border-color: rgba(0, 0, 0, .125);
  }

  .accordion-button {
    span {
      width: 70%;
      line-height: 150%;
    }
  }

  .accordion-button,
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: $bodybackgroundColor;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
    background: transparent;
    filter: drop-shadow(0 0.25rem 2.5rem rgba(0, 0, 0, 0.12));
    font-size: 1.75rem;
    padding: 0;
    padding-bottom: 1.563rem;
    --bs-accordion-border-color: transparent;
    --bs-accordian-border: 0;

  }

  .accordion-button:not(.collapsed) {
    .accordian-heading-green-part {
      color: $primaryGreenColor;
    }
  }

  .accordion-item:first-of-type .accordion-button {
    border: none;
  }


  .accordion {
    border: none;
  }

  .accordion-body {
    padding: 0;
    font-size: 1rem;
  }

  .accordion {
    --bs-accordion-border-color: transparent;
    --bs-accordian-border: 0;
  }

  .accordion-image {
    padding-left: 7.875rem;
  }

  .accordion-button::after, .accordion-button:not(.collapsed)::after {
    background-image: url("../../../public/solutions/datacenter-colocation-assets/icon.svg");
  }

  .accordion-button::not(.collapsed)::after {
    content: "";
    background-image: url("../../../public/solutions/datacenter-colocation-assets/active-icon.svg");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
  }
}
@media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
  .nav-image {
    width: 100%;
  }

  .mega-menu {
    padding-top: 4rem;
  }

  .nav-selected-image {
    width: 100%;
  }
  .mega-menu {
    padding-top: 3rem;
  }
  .nav-container .row {
    align-items: flex-start;
  }
  .navbar{
    height: 57px;
  }
  .fm-logo{
    max-width: 85px;
   }
   .top-nav-wrapper .nav-item {
    font-size: 15px;
}
}
@media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
  .nav-image {
    width: 100%;
  }
  .nav-selected-image {
    width: 100%;
  }
  .nav-item .nav-link{
    font-size: 32px;
  }
  .nav-item .page-link{
    font-size: 18px;
  }
  .nav-container .row {
    align-items: flex-start;
  }
  .mega-menu {
    padding-top: 7rem;
  }
  /* .navbar{
    height: 65px;
  } */
  /* .glc-logo{
    width: 70%;
  } */
  .nav-demo-btn{
    display:none;
  }
  .navbar{
    height: 47px;
  }
  .fm-logo{
    max-width: 85px;
   }
   .top-nav-wrapper .nav-item {
   margin-left: 2rem;
   }


}
@media (min-width: $minTabDevice) and (max-width: $maxTabDevice) {
  /* .navbar{
    height: 65px;
  } */
  /* .glc-logo{
    width: 70%;
  } */
  .nav-demo-btn{
    display:none;
  }
  // .nav-demo{
  //   display: none !important;
  // }
  .image-column{
    display: none;
  }
  .padding-mega-links {
   padding-top: 50px;
}
.nav-container .row {
  align-items: flex-start;
}
.fm-logo{
  max-width: 75px;
 }
}

@media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
  .nav-demo{
    display: none !important;
  }
  .nav-main-list{
    margin-top: 1.5rem !important;
  }
  .accordion-button,
  .accordion-button:not(.collapsed) {
    padding-bottom: 0;
  }
  .accordion-item {
    padding: 1.5rem 0.75rem 1.5rem 1.5rem;
  }
  .navbar-shadow {
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
}

  .nav-demo-btn {
    display: none;
  }

  /* .glc-logo {
    width: 65%;
  } */
  //  .navbar{
  //   height: 44px;
  // }

  .page-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
  }
  .fm-logo{
    max-width: 85px;
   }
   .top-nav-wrapper .nav-item {
    font-size: 15px;
}
}
@media (max-width: $maxTabDevice) {
  .glc-logo {
    max-width: 6.25rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition-property: height;
    transition-duration: .35s;
  }
}