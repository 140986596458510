
@import "../../sassStyles/colors";
@import "../../sassStyles/variables";
.location-modal{
    .modal-content.locations-container {
        max-width: var(--bs-modal-width) !important;
    }
    .modal-content{
        background: $bgPurpleColor;
        padding: 40px;
        border-radius: 12px;
    }
    .modal-header{
        border-bottom: 0;
    }
    .btn-close{
        background: transparent url(../../../public/company/contact-us/btn-close.svg) no-repeat;
    }
    .address-wrapper{
        padding: 30px 10px 30px 10px;
        align-items: center;
        .col-lg-4{
            position: relative;
        }
        h6, p{
            margin-bottom: 0;

        }
    }
    .copy-btn{
        display: none;
    }
    .address-wrapper:hover{
        background-color: black;
        .copy-btn{
            display: block;
        }

    }

    .gradient-border-right::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: -5px;
        width: 2px;
        background: linear-gradient(to bottom, #ff0000, #00ff00);
      }
      .div-copy{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

      }
      @media (max-width: $maxMobileDevice) {
        .modal-content{
            padding: 10px;
            min-height: 550px !important;
            h3{
                text-align: left;
            }
            .address-wrapper{
                padding: 10px 5px 10px 5px;
            }
        }

      }

}
