@import "../../sassStyles/colors";
@import "../../sassStyles/variables";
@import "../../sassStyles/fonts";

.sensus-wrapper {
    .glc-request-demo{
        padding-top: 150px;
    }
    .hero-content {
        background: url("../../../public/products/sensus-assets/sensus-bg.png") no-repeat;
        background-position: center;
        background-size: cover;
        height: 530px;
        padding-top: 80px;
        .hero-subheading{
            margin-top: 23px;
        }
    }


    .product-video {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .product-header {
        padding-bottom: 28.938rem;
        padding-top: 4.813rem;

        h2 {
            width: 80%;
            margin: auto;
        }
    }

    .sensus-diagram-heading {
        padding-top: 100px;
        padding-bottom: 100px;
        h4{
            width: 70%;
            margin: auto;
        }

    }
    .view-btn-width{
        width: 95px;
        height: auto;
        position: absolute;
        right: -3%;
        bottom: -12%;
    }
    .diagram-btn-container{
        margin-top: 60px !important;
    }
    .sensus-video-section{
        margin-top: 100px;
        margin-bottom: 100px;
        h2{
            margin-bottom: 100px;
        }
    }
    .sensus-usecase-section{
        h2{
            margin-bottom: 100px;
        }
        .use-case-wrapper{
            border-radius: 8px;
            padding: 90px 50px 90px 50px;
            margin-bottom: 40px;
            text-align: center;
        }
    }
    .sensus-features-section{
        margin-top: 50px;
        h2{
            margin-bottom: 120px;
        }
        h6{
            width: 85%;
        }
        h3{
            margin-bottom: 35px;
        }
        .feature-element{
            margin-bottom: 100px;
        }
        .image-width{
            width: 60%;
            margin: auto;
        }
        .image-width-led{
            width: 80%;
            margin: auto;

        }
        .image-width-swapable-patch{
            width: 90%;
            margin: auto;

        }
        .margin-left-auto{
            margin-left: auto;
        }

    }
    .sensus-accessories-section {
        margin-top: 120px;
        padding-bottom: 150px;
    }

    .accessories-container {
        margin-top: 100px;
        h4{
            line-height: 130%;
        }

        .rack-row {
            padding-top: 4.644rem;
            padding-bottom: 1.563rem;
            padding-left: 1.25rem;
            margin-right: 0.938rem;
            border: 1px solid #280823;
            border-radius: 8px;

        }

        .mounting-brackets-row {
            padding-top: 2.875rem;
            padding-bottom: 3.265rem;
            padding-left: 1.25rem;
            margin-left: 0.938rem;
            height: 100%;
            border: 1px solid #280823;
            border-radius: 8px;
        }

        .compact-cover-row,
        .rear-cover-row {
            padding: 2.5rem;
            height: 100%;
            border: 1px solid #280823;
            border-radius: 8px;
        }
    }

    .sensus-diagram-text {
        font-size: 1.25rem;
        line-height: 140%;
    }

    .sensus-diagram-cta {
        padding-left: 2.75rem;
        padding-right: 2.75rem;
    }

    .product-info-header {
        padding-top: 8.125rem;
        padding-bottom: 10.313rem;

        h2 {
            padding-top: 4.563rem;
            line-height: 124%;

            div {
                -webkit-text-stroke-width: 0.063rem;
                -webkit-text-stroke-color: $primaryGreenColor;
                font-size: 10.063rem;
                line-height: 124%;
                color: transparent;
            }
        }
    }

    .features-container {
        margin-top: 0;
    }

}

@media (min-width: $maxExtraLargeDevice) {
    .product-video {
        object-fit: contain !important;
    }
}

@media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
    .sensus-wrapper {
        .product-info-header {
            h2 {
                div.more-products-text {
                    font-size: 7.5rem;
                }
            }
        }

        .passive-patch-panel-section {
            .text-gradient {
                font-size: 2.375rem;
            }
        }
    }
}

@media (min-width: $midSmallDevice) and (max-width: $minMediumDevice) {
    .sensus-wrapper {
        .hero-content {
            height: auto;
            margin-top: 80px;
        }
        .sensus-diagram-heading {
            padding-bottom: 0rem;
        }

        .sensus-intelligence-section {
            margin-bottom: 9.375rem;
        }

        .passive-patch-panel-section {
            margin-bottom: 11.75rem;

            .text-gradient {
                font-size: 2rem;
            }
        }

        .sensus-accessories-section {
            margin-top: 10.187rem;
        }

        .accessories-container {
            margin-top: 5.75rem;
            margin-bottom: 5.4rem;
        }

        .product-info-header {
            padding-top: 5.4rem;
            padding-bottom: 8.867rem;

            h2 {
                div.more-products-text {
                    font-size: 7.06rem;
                }
            }
        }
    }
}

@media (max-width: $midSmallDevice) and (min-width: $maxTabDevice) {
    .sensus-wrapper {
        .hero-content {
            height: auto;
            margin-top: 80px;
        }
        .accessories-container {
            .rack-row {
                margin-right: 0;
            }

            .mounting-brackets-row {
                margin-left: 0;
            }
        }

        .accessories-container {
            margin-top: 5.75rem;
            margin-bottom: 5.4rem;
        }

        .product-info-header {
            padding-top: 5.4rem;
            padding-bottom: 8.867rem;

            h2 {
                div.more-products-text {
                    font-size: 5.875rem;
                }
            }
        }
    }
}

@media (max-width: $maxMobileDevice) {
    .sensus-wrapper {
        .sensus-diagram-heading {
            padding-top: 7rem;
            padding-bottom: 0;
        }

        .sensus-intelligence-section {
            margin-bottom: 6rem;
        }

        .passive-patch-panel-section {
            margin-bottom: 4.5rem;
        }

        .sensus-accessories-section {
            margin-top: 4.375rem;

            .accessories-container {
                margin-top: 3rem;
                margin-bottom: 0;

                .rack-row {
                    margin-right: 0;
                }

                .mounting-brackets-row {
                    margin-left: 0;
                }
            }
        }

        .product-info-header {
            padding-top: 5.4rem;
            padding-bottom: 4rem;

            h2 {
                span {
                    font-size: 1.187rem;
                }

                div.more-products-text {
                    font-size: 3.187rem;
                }
            }
        }

        .container.sensus-challange-section,
        .hero-content {
            padding-left: 0px;
            padding-right: 0px;
        }
        .sensus-svg {
            max-height: 160px;
            margin-top: 20px;
        }
    }
}