@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.error-wrapper {
    .error-heading {
        text-shadow: 0 0.25rem 2.5rem rgba(72, 183, 75, 0.5);
        font-size: 12.5rem;
        line-height: 12.5rem;
    }
    .primary-btn{
        position: relative;
        z-index: 3;
    }

    .error-info {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 199%;
    }

    .error-btn {
        margin-top: 2.25rem;
        padding-left: 2.313rem;
        padding-right: 2.313rem;
    }

    .bg-gradient-left {
        position: absolute;
        background: $bgGradient;
        transform: translate3d(0, 0, 0);
        width: 510px;
        height: 510px;
        filter: blur(12.5rem);
        z-index: 1;
        left: 0;
        top: 10%;
        bottom: 0;
        margin: auto;
    }
    .lines-left{
        position: absolute;
        background: url("../../../public/errors/lines-left.png") no-repeat;
        z-index: 2;
        width: 100%;
        height: 510px;
        left: 0;
        top: 10%;
        bottom: 0;
        margin: auto;

    }
    .lines-right{
        position: absolute;
        background: url("../../../public/errors/lines-right.png") no-repeat;
        z-index: 2;
        width: 750px;
        height: 510px;
        right: 0;
        bottom: 0;
        margin: auto;

    }
    .wire-left{
        position: absolute;
        background: url("../../../public/errors/wire-left.png") no-repeat;
        z-index: 3;
        width: 100%;
        height: 100%;
        left: -2%;
        top: 30%;
        bottom: 0;
        margin: auto;

    }
    .wire-right{
        position: absolute;
        background: url("../../../public/errors/wire-right.png") no-repeat;
        z-index: 3;
        width: 750px;
        height: 100%;
        right: 0;
        top: 92%;
        bottom: 0;
        margin: auto;

    }
    .bg-gradient-right {
        position: absolute;
        background: $bgGradient;
        transform: translate3d(0, 0, 0);
        width: 510px;
        height: 510px;
        filter: blur(12.5rem);
        z-index: 1;
        right: 0;
        bottom: 0;
        margin: auto;
    }
    @media (max-width: $maxMobileDevice) {
        .error-heading{
            font-size: 102px;
            line-height: 103px;
        }
        .bg-gradient-left, .bg-gradient-right {
            display: none;

        }
        .wire-left , .wire-right, .lines-left, .lines-right{
            display: none;
        }
        .error-info{
            font-size: 14px;
        }

    }
}