@import "../../sassStyles/colors";
@import "../../sassStyles/variables";
@import "../../sassStyles/fonts";

.apd-wrapper {
    .hero-content {
        background: url("../../../public/products/apd-assets/apd-bg.png") no-repeat;
        background-position: center;
        background-size: cover;
        height: 815px;
        padding-top: 200px;

        .hero-subheading {
            margin-top: 23px;
        }

        .apd-logo-banner {
            margin-bottom: 30px;
        }
    }
    .apd-banner-image{
        margin-top: -20%;
    }


    .product-video {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .product-header {
        padding-bottom: 28.938rem;
        padding-top: 4.813rem;

        h2 {
            width: 80%;
            margin: auto;
        }
    }

    .apd-diagram-container {
        margin-bottom: 160px;
    }

    .apd-diagram-heading {
        // padding-top: 100px;
        padding-bottom: 60px;

        h4 {
            width: 70%;
            margin: auto;
        }

    }

    .diagram-btn-container {
        // margin-top: -10% !important;

        .btn {
            min-width: 194px;
            position: relative;
            z-index: 1;

        }
    }
        //APD Slider Section
        .slider {
            width: 100%;
            margin: auto;
            position: relative;
            display: grid;
            place-items: center;
            overflow: hidden;

            .slide-track {
                display: flex;
                width: calc((37.5rem + 3.75rem) * 3 * 1);
            }

            .slide {
                width: 37.5rem;
                height: 100%;
                display: flex;
                align-items: center;
                margin: 0 3.75rem 3.75rem 0;

                img {
                 max-width: 42rem !important;
                }
            }
        }

        .slider1 {
            .slide-track {
                animation: scroll1 15s linear infinite;
            }

            @keyframes scroll1 {
                0% {
                    transform: translateX(0);
                }

                100% {
                    // -1 * (slide width + margin offset) * no. of slides
                    transform: translateX(calc(-1 * (37.5rem + 3.75rem) * 3));
                }
            }
        }

        .slider2 {
            .slide-track {
                animation: scroll2 15s linear infinite;
            }

            @keyframes scroll2 {
                0% {
                    // -1 * (slide width + margin offset) * no. of slides
                    transform: translateX(calc(-1 * (37.5rem + 3.75rem) * 3));
                }

                100% {
                    transform: translateX(0);
                }
            }
        }

    .apd-slider-section {
        margin-top: 100px;
        margin-bottom: 100px;

        h2 {
            margin-bottom: 100px;
        }
    }


    .apd-usecase-section {
        padding-bottom: 150px;
        h2 {
            margin-bottom: 100px;
        }

        .use-case-wrapper {
            border-radius: 8px;
            padding-left: 15px;
            padding-right: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 40px;
            text-align: center;
            min-height: 222px;
        }
    }

    .apd-features-section {
        margin-top: 50px;

        h2 {
            margin-bottom: 120px;
        }

        h6 {
            width: 85%;
        }

        h3 {
            margin-bottom: 35px;
        }

        .feature-element {
            margin-bottom: 100px;
        }

        .image-width {
            width: 60%;
            margin: auto;
        }

        .image-width-led {
            width: 80%;
            margin: auto;

        }

        .image-width-swapable-patch {
            width: 90%;
            margin: auto;

        }

        .margin-left-auto {
            margin-left: auto;
        }

    }

    .product-info-header {
        padding-top: 8.125rem;
        padding-bottom: 10.313rem;

        h2 {
            padding-top: 4.563rem;
            line-height: 124%;

            div {
                -webkit-text-stroke-width: 0.063rem;
                -webkit-text-stroke-color: $primaryGreenColor;
                font-size: 10.063rem;
                line-height: 124%;
                color: transparent;
            }
        }
    }

    .features-container {
        margin-top: 0;
    }

}
@media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
    .product-video {
        object-fit: contain !important;
    }
    .apd-wrapper {
    .apd-banner-image {
        margin-top: -30%;
    }
}
}


@media (min-width: $midSmallDevice) and (max-width: $maxMediumDevice) {
    .apd-wrapper {
        .hero-content {
            height: 650px;
            padding-top: 150px;
        }

        .apd-banner-image {
            margin-top: -40%;
        }

        .apd-diagram-container {
            margin-bottom: 100px;

            .apd-diagram-heading {
                padding-bottom: 40px;

                h4 {
                    width: 75%;
                }
            }
        }

        .apd-slider-section {
            margin-top: 70px;
            margin-bottom: 70px;

            h2 {
                margin-bottom: 70px;
            }
        }

        .apd-usecase-section {
            padding-bottom: 70px;

            .use-case-wrapper {
                min-height: 150px;
            }
        }
        .slider {
            .slide {
                width: 30rem;
                margin: 0 1.75rem 1.75rem 0;

                img {
                    max-width: 30rem !important;
                }
            }

        }

        .apd-features-section {
            h2 {
                margin-bottom: 70px;
            }


            h3 {
                margin-bottom: 35px;
            }

            .feature-element {
                margin-bottom: 70px;
            }

            h6 {
                margin-bottom: 30px;
            }
        }
    }
}

@media (max-width: $midSmallDevice) and (min-width: $maxTabDevice) {
    .apd-wrapper {
        .hero-content {
            height: 500px;
            padding-top: 175px;

            h6 {
                width: 80%;
                margin: auto;
            }
        }

        .apd-diagram-container {
            margin-bottom: 60px;
        }

        .apd-banner-image {
            margin-top: -25%;
        }

        .apd-slider-section {
            margin-top: 70px;
            margin-bottom: 60px;
        }

        .apd-diagram-heading h4 {
            width: 100%;
            margin: auto;
        }

        .apd-diagram-heading {
            padding-bottom: 35px;
        }

        .apd-slider-section h2 {
            margin-bottom: 60px;
        }

        .slider {
            .slide {
                width: 100%;
                margin: 0 0.75rem 0.75rem 0;

                img {
                    max-width: 100% !important;
                }
            }

        }

        .apd-features-section {
            h2 {
                margin-bottom: 60px;
            }

            h3 {
                margin-bottom: 20px;
            }

            .feature-element {
                margin-bottom: 60px;
            }

            h6 {
                margin-bottom: 20px;
            }
        }

        .apd-usecase-section {
            .use-case-wrapper {
                min-height: 150px;
                border-radius: 6px;

                h6 {
                    line-height: 27px;
                }
            }

            padding-bottom: 60px;

            h2 {
                margin: auto;
                margin-bottom: 60px;
                width: 70%;
            }
        }

    }


}

@media (max-width: $maxMobileDevice) {
    .apd-wrapper {
        .hero-content {
            height: 530px;

            h6 {
                width: 80%;
                margin: auto;
            }
        }

        .apd-diagram-container {
            margin-bottom: 50px;
        }

        .apd-banner-image {
            margin-top: -46%;
        }

        .apd-slider-section {
            margin-top: 70px;
            margin-bottom: 50px;
        }

        .apd-diagram-heading h4 {
            width: 100%;
            margin: auto;
        }

        .apd-diagram-heading {
            padding-bottom: 30px;
        }

        .apd-slider-section h2 {
            margin-bottom: 50px;
        }

        .slider {
            .slide {
                width: 100%;
                margin: 0 0.75rem 0.75rem 0;

                img {
                    max-width: 100% !important;
                }
            }

        }

        .apd-features-section {
            h2 {
                margin-bottom: 50px;
            }

            .element-column-reverse {
                display: flex;
                flex-direction: column-reverse;
            }

            h3 {
                margin-bottom: 25px;
            }

            .feature-element {
                margin-bottom: 50px;
            }

            h6 {
                margin-bottom: 20px;
            }
        }

        .apd-usecase-section {
            .use-case-wrapper {
                min-height: 150px;
                border-radius: 6px;

                h6 {
                    line-height: 27px;
                }
            }

            padding-bottom: 50px;

            h2 {
                margin-bottom: 50px;
            }
        }

    }

}