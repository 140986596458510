@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.glc-resources-wrapper {
    // transform: translateZ(0);

    .hero-content {
        // height: 100vh;
        max-height: 600px;
        background: url("../../../public/company/resources/resources-bg.png") no-repeat;
        background-size: cover;
        background-position: center;
    }

    .resource-hero-info {
        text-align: center;
        margin-top: 23px;
        margin-bottom: 49px;
        z-index: 1;
    }

    .btn-doc-portal {
        padding-left: 62px;
        padding-right: 62px;
        z-index: 1;
    }
}

.featured-content-wrapper {
    margin-bottom: 120px;
    margin-top: 120px;

    .heading-featured-content {
        margin-bottom: 100px;
    }

    .text-product {
        width: 82%;
        line-height: 150%;
        font-size: 28px;
        margin: auto;
        text-align: center;
        margin-top: 63px;
        margin-bottom: 63px;
    }

    .slick-slide {
        transform: translateZ(0);
    }

    .product-element {
        border-radius: 8px;
        padding-top: 40px;
        height: auto;
    }

    .btn-read-more {
        width: 184px;
        margin-bottom: 50px;
    }

    .text-element {
        width: 80%;
        margin: auto;
        margin-top: 22px;
        margin-bottom: 22px;
        min-height: 150px;
    }

    .product-text {
        width: 81%;
        margin: auto;
        margin-bottom: 28px;
        min-height: 145px;
    }

    .slick-dots li.slick-active button:before {
        opacity: .75;
        color: $primaryRedColor;
    }

    .slick-dots li button:before {
        font-family: 'slick';
        font-size: 5rem;
        line-height: 1.25rem;
        position: absolute;
        top: 0.938rem;
        left: 0;
        width: 2.5rem;
        height: 2.5rem;
        content: '-';
        text-align: center;
        opacity: .25;
        color: #000;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.glc-resources {
    .resources-pills-wrapper {
        overflow-x: auto;

        .nav {
            background-color: #fff;
            border: 1px solid $primaryRedColor;
        }

        .nav-pills {
            border-radius: 1000px;
            border: 1px solid $primaryRedColor;
            text-align: center;
            margin-bottom: 135px;
            margin-top: 80px;
            padding: 10px 0px;
            display: flex;
            align-items: center;
            max-width: 950px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            --pills-count: 5;
            --total-width: 100%;
            justify-content: space-evenly;

            li {
                flex: 0 0 auto;
                margin-right: 10px;
            }

            .colorIndicator {
                position: absolute;
                width: calc(var(--total-width) / var(--pills-count));
                background-color: #000;
                transition: transform 0.3s ease;
                z-index: -1;
                border-radius: 100px;
            }

            .nav-link {
                color: $bgPurpleColor;
                background-color: transparent;
                border: none;
                outline: none;
                transition: color 0.3s ease;
                cursor: pointer;
                font-size: 28px;

                &.active {
                    color: #fff;
                    background-color: $primaryRedColor;
                    font-weight: 700;
                    border-radius: 1000px;
                }
            }

            .nav-item {
                flex: 0 0 auto;
                margin-right: 10px;
            }
        }
    }
}

.icon-max-height {
    max-height: 130px;
    max-width: 100%;
}

.resources-wrapper {
    padding-top: 100px;
    padding-bottom: 100px;

    .resource-element {
        border-radius: 5.34929px;
        padding-top: 40px;
        padding-bottom: 40px;
        height: auto;
        padding-left: 30px;
        padding-right: 20px;
        position: relative;
    }

    .resource-element-heading {
        margin-top: 30px;
        margin-bottom: 20px;
        line-height: 36px;
        font-weight: 600;
        min-height: 72px;

    }
}

.blog-container {
    margin-bottom: 150px;
}

.resources-wrapper .video-element {
    padding-top: 24px;
    padding-left: 22px;
    padding-right: 22px;
    height: 440px;
}

@media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
    .featured-content-wrapper {
        .text-element{
            min-height: 120px;
        }
    }
    .nav-pills .nav-link.active {
        border-radius: 1000px;
    }

    .nav-item {
        margin-right: 1rem;
    }
     .glc-resources.resources-pills-wrapper .nav-pills {
        max-width: 800px;
     }

    .glc-resources .resources-pills-wrapper .nav-pills .nav-link {
        font-size: 20px;
        line-height: 24px;
    }

    .resources-wrapper .video-element {
        padding-top: 24px;
        padding-left: 22px;
        padding-right: 22px;
        height: 400px;
    }
    .product-text{
        min-height: 170px;
    }

}

@media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
    .featured-content-wrapper {
        .text-element {
            font-size: 1.25rem;
            line-height: 1.875rem;
        }

        .product-element {
            height: 465px;
        }
    }

    .glc-resources .resources-pills-wrapper .nav-pills {
        .nav-item {
            .nav-link {
                font-size: 1.25rem;
            }
        }
    }

    .resources-wrapper {
        padding-top: 6rem;

        .resource-element {
            padding: 40px 24px 45px 30px;

            &.datasheet {
                height: 620px;
            }

            &.ebook {
                height: 600px;
            }

        }
    }
}

@media (min-width: $midSmallDevice) and (max-width: $maxSmallDevice) {
    .featured-content-wrapper {
        .text-element {
            font-size: 1.125rem;
            line-height: 1.68rem;
        }

        .product-element {
            height: 435px;
        }
    }

    .glc-resources .resources-pills-wrapper .nav-pills {
        .nav-item {
            .nav-link {
                font-size: 1.125rem;
            }
        }
    }

    .resources-wrapper {
        padding-top: 5.25rem;

        .resource-element {
            padding: 40px 24px 45px 30px;
            height: 510px;

            &.video-element {
                height: 450px;
            }
        }
    }

    .primary-btn {
        font-size: 0.75rem;
    }
}

@media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {

    .featured-content-wrapper {
        .btn-read-more {
            max-width: 150px;
        }

        .text-element {
            font-size: 0.875rem;
            line-height: 1.68rem;
        }

        .product-element {
            height: 470px;

            .product-text {
                line-height: 1.125rem;
            }
        }
    }

    .glc-resources .resources-pills-wrapper .nav-pills {
        margin-top: 3.125rem;

        .nav-item {
            margin-left: 0.5rem;

            .nav-link {
                font-size: 1.125rem;
                padding: 8px 13px;
            }
        }
    }

    .resources-wrapper {
        padding-top: 3.125rem;

        .resource-element {
            padding: 40px 24px 45px 30px;
            height: 580px;

            &.video-element {
                height: 350px;
            }
        }
    }
}

@media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
    .glc-resources-wrapper {
        .resource-hero-info {
            width: 90%;
        }
         .btn-doc-portal {
            padding-left: 0;
            padding-right : 0;
         }


        .hero-content {
            background-size: cover;
            background-position: center;

            h1 {
                z-index: 1;
            }
        }

        .nav {
            background-color: transparent;
        }
        .featured-content-wrapper {
            margin-bottom: 60px;
            margin-top: 60px;
        }
        .glc-resources{
            margin-top: 100px;
        }

        .glc-resources .resources-pills-wrapper .nav-pills {
            overflow-x: auto;
            flex-direction: column;
            height: 45px;
            padding: 0;
            border-radius: 0;
            margin-top: 50px;
            border: none;

            .nav-item {
                margin-left: 0.687rem;

                .nav-link {
                    font-size: 0.875rem;
                    background-color: #fff;
                    border-radius: 100px;
                    border: 1px solid $primaryRedColor;
                    color: #000;
                    &.active {
                        color: #fff;
                        background-color: $primaryRedColor;
                    }
                }
            }

        }
    }

    .featured-content-wrapper {
        .heading-featured-content {
            margin-bottom: 30px;
            z-index: 1;
            position: relative;
        }

        .product-element {
            height: auto;
            margin: 5px !important;
            padding-bottom: 34px;

            .btn-read-more {
                margin-bottom: 0;
            }

            .product-text {
                line-height: 21px;
                min-height: auto;
            }

            .text-element {
                line-height: 24px;
                min-height: auto;

                span {
                    font-size: 20px;
                }
            }
        }
    }

    .resources-wrapper {
        padding-top: 43px;

        .resource-element {
            padding: 28px 32px 33px 34px;
            height: auto;
            margin-bottom: 20px;

            .primary-btn {
                min-width: 0;
            }

            .btn-download {
                position: relative;
                bottom: 0;
            }
        }
    }
}