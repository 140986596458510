@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.about-us-wrapper {

    .hero {

        .hero-content {
            background: url("../../../public/company/about-us/about-us-bg.png") no-repeat;
            background-position: center;
            background-size: cover;
            height: 530px;
        }

    }

    .world-map {
        margin-bottom: 100px;
    }

    .our-story-bg {
        background: url("../../../public/company/about-us/our-story-bg.png") no-repeat;
        background-position: center;
        background-size: cover;
        padding-top: 31px;
        padding-bottom: 115px;
        margin-top: 82px;

        .glc-story {
            font-size: 60px;
        }

        .about-info-bold {
            font-weight: 700;
        }
    }


    .vision-mission-wrapper {
        padding-bottom: 125px;
        padding-top: 125px;


        .header {
            font-weight: 300;
            padding-top: 33px;
            padding-bottom: 125px;

            span {
                font-weight: 400;
                color: $primaryRedColor;

            }
        }
    }

    .glc-vision {

        h5 {
            margin-top: 40px;
            width: 98%;
        }

        .vision-image-wrapper {
            text-align: right;
        }

        img {
            width: 89%;
            max-height: 720px;
        }
    }

    .glc-mission {


        h5 {
            margin-top: 40px;
            // margin-bottom: 40px;
            width: 98%;
        }

        img {
            width: 89%;
            max-height: 720px;
        }

    }

    .ceo-message-wrapper {
        margin-top: 150px;
        margin-bottom: 150px;

        .message-heading {
            font-weight: 400;
        }

        .content {
            .quote-margin {
                margin-bottom: 50px;
            }

            margin-top: 70px;
        }

        .message-text {
            width: 99%;
            line-height: 150%;
        }
    }

    .glc-leadership {
        margin-top: 200px;
        margin-bottom: 100px;

        .text-leadership-stroke {
            font-style: normal;
            font-size: 150px;
            line-height: 170%;
            -webkit-text-stroke-width: 0.72px;
            -webkit-text-stroke-color: $primaryGreenColor;
            color: transparent;
            opacity: 0.3;
            text-transform: uppercase;
        }

        .brand-margin {
            margin-top: -9rem;
            margin-bottom: 60px;
        }

        .text-behind-brand {
            width: 60%;
            text-align: center;
            margin: auto;
            margin-bottom: 90px;
            margin-top: 38px;

        }

        .leader-element {
            text-align: center;
            margin-top: 66px;
        }

        .leader-info {
            text-align: center;
        }

        .leader-name {
            font-size: 32px;
        }
    }

    .products-container {
        margin-left: 0;
        margin-right: 0;
        padding-left: 120px;
        padding-right: 120px;

    }
    .btn-story {
        font-size: 24px;
        color: $primaryRedColor;
        text-decoration: none;
    }
    .dream-heading{
        background-color: $bgPurpleColor;
        padding: 17px 22px 17px 22px;
        border-radius: 12px;
        width: 60%;
        margin: auto;
    }
}
@media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {

    .about-us-wrapper {
        .hero {
            .hero-content {
                height: 460px;
                padding-top: 68px;

            }

        }


        .world-map {
            margin-bottom: 100px;
        }

        .our-story-bg {
            background: url("../../../public/company/about-us/our-story-bg.png") no-repeat;
            background-position: center;
            background-size: cover;
            padding-top: 0;
            padding-bottom: 70px;
            margin-top: 70px;

            .glc-story {
                font-size: 60px;
            }

            .about-info-bold {
                font-weight: 700;
            }
        }


        .vision-mission-wrapper {
            padding-bottom: 70px;
            padding-top: 70px;


            .header {
                font-weight: 300;
                padding-top: 35px;
                padding-bottom: 90px;

                span {
                    font-weight: 400;
                    color: $primaryRedColor;

                }
            }
        }

        .glc-vision {

            h6 {
                margin-top: 14px;
                width: 98%;
            }

            .vision-image-wrapper {
                text-align: right;
            }

            img {
                width: 100%;
                max-height: 720px;
            }
        }

        .glc-mission {


            h6 {
                margin-top: 14px;
                width: 98%;
            }

            img {
                width: 100%;
                max-height: 720px;
            }

        }

        .ceo-message-wrapper {
            margin-top: 80px;
            margin-bottom: 80px;

            .message-heading {
                font-weight: 400;
            }

            .content {
                .quote-margin {
                    margin-bottom: 50px;
                }

                margin-top: 70px;
            }

            .message-text {
                width: 99%;
                line-height: 150%;
            }
        }

        .glc-leadership {
            margin-top: 200px;
            margin-bottom: 100px;

            .text-leadership-stroke {
                font-style: normal;
                font-size: 150px;
                line-height: 170%;
                -webkit-text-stroke-width: 0.72px;
                -webkit-text-stroke-color: $primaryGreenColor;
                color: transparent;
                opacity: 0.3;
                text-transform: uppercase;
            }

            .brand-margin {
                margin-top: -9rem;
                margin-bottom: 60px;
            }

            .text-behind-brand {
                width: 60%;
                text-align: center;
                margin: auto;
                margin-bottom: 90px;
                margin-top: 38px;

            }

            .leader-element {
                text-align: center;
                margin-top: 66px;
            }

            .leader-info {
                text-align: center;
            }

            .leader-name {
                font-size: 32px;
            }
        }

        .products-container {
            margin-left: 0;
            margin-right: 0;
            padding-left: 120px;
            padding-right: 120px;

        }
        .btn-story {
            font-size: 24px;
            color: $primaryRedColor;
            text-decoration: none;
        }
        .dream-heading{
            padding: 13px 16px 13px 16px;
            width: 68%;
            border-radius: 10px;
        }
    }

}
@media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {

    .about-us-wrapper {
        .hero-subheading{
            margin-top: 20px;
        }
        .world-map {
            margin-bottom: 50px;
        }

        .our-story-bg {
            padding-bottom: 50px;
            margin-top: 50px;
            padding-top: 0;

            .about-info-bold {
                font-weight: 700;
            }
        }


        .vision-mission-wrapper {
            padding-bottom: 50px;
            padding-top: 50px;


            .header {
                font-weight: 300;
                padding-top: 35px;
                padding-bottom: 40px;
                width: 90%;
                margin: auto;

                span {
                    font-weight: 400;
                    color: $primaryRedColor;

                }
            }
        }

        .glc-vision {

            .our-vision-wrapper{
                flex-direction: column-reverse;
                margin-top: 50px;
            }
            h3{
                text-align: center;
            }

            h6 {
                margin-top: 14px;
                width: 98%;
            }

            .vision-image-wrapper {
                text-align: right;
            }

            img {
                width: 100%;
                max-height: 720px;
            }
        }

        .glc-mission {
            h3{
                text-align: center;
            }


            h6 {
                margin-top: 14px;
                width: 98%;
            }

            img {
                width: 100%;
                max-height: 720px;
            }

        }

        .ceo-message-wrapper {
            margin-top: 50px;
            margin-bottom: 50px;

            .message-heading {
                font-weight: 400;
                text-align: center;
            }

            .content {
                .quote-margin {
                    margin-bottom: 50px;
                }

                margin-top: 50px;
            }

            .message-text {
                width: 99%;
                line-height: 150%;
            }
        }

        .glc-leadership {
            margin-top: 200px;
            margin-bottom: 100px;

            .text-leadership-stroke {
                font-style: normal;
                font-size: 150px;
                line-height: 170%;
                -webkit-text-stroke-width: 0.72px;
                -webkit-text-stroke-color: $primaryGreenColor;
                color: transparent;
                opacity: 0.3;
                text-transform: uppercase;
            }

            .brand-margin {
                margin-top: -9rem;
                margin-bottom: 60px;
            }

            .text-behind-brand {
                width: 60%;
                text-align: center;
                margin: auto;
                margin-bottom: 90px;
                margin-top: 38px;

            }

            .leader-element {
                text-align: center;
                margin-top: 66px;
            }

            .leader-info {
                text-align: center;
            }

            .leader-name {
                font-size: 32px;
            }
        }

        .products-container {
            margin-left: 0;
            margin-right: 0;
            padding-left: 120px;
            padding-right: 120px;

        }
        .btn-story {
            font-size: 24px;
            color: $primaryRedColor;
            text-decoration: none;
        }
        .dream-heading{
            padding: 13px 16px 13px 16px;
            width: 100%;
            border-radius: 10px;
        }
        .story-heading{
            text-align: center;
            margin-bottom: 30px;
        }
    }

}

