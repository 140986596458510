@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.contact-us {
    background-color: $bodybackgroundColor;

    .success-message {
        color: $primaryGreenColor;
        margin-top: 10px;
        font-weight: 700;
    }

    .error-message {
        color: $primaryRedColor;
        margin-top: 10px;
        font-weight: 700;
    }

    .contact-page {
        padding-top: 60px;
    }

    .contact-tile-container {
        padding-top: 13rem;
        padding-bottom: 13rem;
        margin-bottom: 89px;
        background-image: url(../../../public/backgrounds/contact-us-bg.png);
        background-position: center;

        .contact-title {
            margin-bottom: 1rem;
            color: $colorWhite;
        }
    }

    .world-map {
        margin-bottom: 100px;
    }

    .contact-us-form {
        width: 80%;
        margin: auto;
        margin-top: 40px;
    }

    .hs-input[type=checkbox],
    .hs-input[type=radio] {
        cursor: pointer;
        width: auto;
        height: auto;
        padding: 0;
        margin: 3px 5px 3px 0px;
        line-height: normal;
        border: none;
    }

    .inputs-list {
        margin: 0 0 5px;
        width: 100%;
        padding-left: 5px;
    }

    .inputs-list>li {
        display: block;
        padding: 0;
        width: 100%;
        padding-top: 0;
    }

    .contact-form-container {
        position: relative;
        margin-bottom: 8.125rem;
        padding: 80px 0;
        z-index: 3;
        max-width: 78rem;

        /* .form-title {
        margin-top: 100px;
    } */

        .form-row {
            display: flex;
            justify-content: space-between;
            max-width: 99%;
        }

        .checkbox-input {
            display: flex;
            align-items: center;
        }

        .checkbox-input-span {
            display: flex;
            margin: auto;
            margin-left: 10px;

        }

        .inputs-list label {
            float: none;
            width: auto;
            padding: 0;
            line-height: 18px;
            white-space: normal;
            font-weight: normal;
            display: flex;
            align-items: center;
        }

        .form-group {
            flex: 1;
            display: flex;
            flex-direction: column;
            margin-right: 10px;
            margin-bottom: 10px;
        }

        .form-group:last-child {
            margin-right: 0;
        }

        input,
        textarea {
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 5px;
            padding: 10px 15px;
            min-height: 40px;
            background-color: #f5f8fa;
            border: 1px solid #cbd6e2;
        }

        .error {
            color: red;
            font-size: 0.8em;
            margin-top: -5px;
            margin-bottom: 10px;
        }

        .nav-item .nav-link {
            padding-top: 40px;
        }
    }


    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link {
        color: var(--bs-nav-tabs-link-active-color);
        background-color: transparent;
        border: 0;
    }

    .tab-pane.active {
        padding-top: 6.25rem;
        padding-bottom: 6.25rem;
    }

    .nav-link.active {
        background-color: $secondaryPinkColor;
    }

    .nav-tabs {
        border-bottom: 0;
        background: transparent;

    }

    .tab-content {
        background-color: $secondaryPinkColor;
    }

    .nav-tabs .nav-item {
        padding: 0;
        margin-right: 0
    }

    @media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
        .contact-page {
            padding-top: 0;

        }

        .contact-tile-container {
            height: 460px;
        }

    }

    @media (min-width: $midSmallDevice) and (max-width: $maxMediumDevice) {
        .contact-form-container {
            padding-bottom: 0;

            .hubspot-form {
                margin-top: 2.25rem;
                margin-left: 50px;
                margin-right: 50px;
            }

            .tab-pane {
                padding-bottom: 3.625rem;
            }
        }
    }

    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        .contact-tile-container {
            .contact-subtitle {
                width: 80%;
            }
        }

        .contact-form-container {
            padding-bottom: 0;

            .hubspot-form {
                margin-top: 2.25rem;
                margin-left: 50px;
                margin-right: 50px;
            }

            .tab-pane {
                padding-bottom: 3.625rem;
            }
        }

        .glc-help-center {
            margin-bottom: 100px;
        }
    }

    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        .contact-tile-container {
            margin-bottom: 50px;

            .contact-subtitle {
                width: 82%;
                margin: auto;
            }
        }

        .contact-page {
            padding-top: 0;
        }

        .contact-us-form {
            width: 90%;
        }
        .form-row{
            flex-direction: column;
            width: 100%;
        }
        .contact-form-container  .form-group{
            margin-right: 0;
        }
        .checkbox-input-span{

            font-size: 12px;

        }

        .contact-form-container {
            padding-bottom: 40px;
            padding-top: 40px;
            margin-bottom: 5rem;

            .nav-item {
                .nav-link {
                    padding: 13px;

                    h4 {
                        font-size: 8px;
                    }
                }
            }


            .hubspot-form {
                margin-top: 2.25rem;
                margin-left: 28px;
                margin-right: 28px;

                .hs-form-field .hs-input {
                    width: 100% !important;
                }

            }

            .tab-pane {
                padding-top: 3.5rem;
                padding-bottom: 3.625rem;

                h3 {
                    font-size: 16px;
                }
            }
        }

        .glc-help-center {
            margin-bottom: 70px;
        }
    }

}