@import "../../sassStyles/global";
@import "../../sassStyles/colors";

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.all-solutions-wrapper .hero-content {
  height: 100vh;
  text-align: center;
  flex-direction: column;
  background-image: url(../../../public/backgrounds/enterprise-solutions-bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 150px;

  .hero-content-sub-heading {
    margin-top: 20px;
  }

  .hero-sub-heading {
    text-align: center;
    color: $secondaryWhiteColor;
  }
}
